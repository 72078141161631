import React, { Component } from "react";
import axios from "axios";
import "./Login.css";
import logo from "../../logo_transparente.png";
import { handleError } from "../Plugins/Config/Config";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.location.state
        ? this.props.location.state.message
        : "",
    };
  }

  qtd(v) {
    v = String(v).replace(/\D/g, ""); // permite digitar apenas numero
    return v;
  }
  signIn = (e) => {
    e.preventDefault();
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("senha");
    localStorage.removeItem("agencia");
    localStorage.removeItem("supervisor");
    localStorage.removeItem("nome");

    localStorage.setItem("credentials", "basic " + "admin" + ":" + "admin");
    localStorage.setItem("username", "admin");
    localStorage.setItem("password", "admin");
    var login = this.email == undefined ? "" : this.email.trim();
    var pass = this.senha == undefined ? "" : this.senha.trim();
    var agencia = this.agencia == undefined ? "" : this.agencia.trim();

    if (login !== "" && pass !== "" && agencia !== "") {
      axios
        .get(
          "http://52.11.139.178/sicoob/login/" +
            agencia +
            "/" +
            login +
            "/" +
            pass,
          {
            auth: {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password"),
            },
          }
        )
        .then((response) => {
          if (response) {
            // console.log(response);
            localStorage.setItem("id", response.data[0].id);
            localStorage.setItem("email", response.data[0].email);
            localStorage.setItem("senha", response.data[0].senha);
            localStorage.setItem("agencia", response.data[0].agencia);
            localStorage.setItem("supervisor", response.data[0].supervisor);
            localStorage.setItem("nome", response.data[0].nome);
            localStorage.setItem("cracha", response.data[0].cracha);
            this.props.history.push("/home");
            return;
          }
        })
        .catch((error) => {
          handleError(error.response.data.message);
          console.log(error.response);
        });
    } else {
      handleError("Preencha todos campos");
    }
  };

  render() {
    if (this.state.message !== "") {
      handleError(this.state.message);
    }
    return (
      <div className="main">
        <div className="container_login">
          <center>
            <div className="middle">
              <div id="login">
                <form onSubmit={this.signIn}>
                  <fieldset className="clearfix_login">
                    <span
                      style={{
                        width: "50%",
                        textalign: "center",
                        marginBottom: "30px",
                        display: "inline-block",
                      }}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: "150px", marginLeft: "-15px" }}
                      />
                    </span>
                    <p>
                      <span className="fa fa-home"></span>
                      <input
                        type="text"
                        id="agencia"
                        onKeyUp={(e) => this.qtd(e)}
                        onChange={(e) => (this.agencia = e.target.value)}
                        placeholder="Agencia"
                        required
                      />
                    </p>
                    <p>
                      <span className="fa fa-user"></span>
                      <input
                        type="text"
                        id="email"
                        onChange={(e) => (this.email = e.target.value)}
                        placeholder="Id"
                        required
                      />
                    </p>
                    <p>
                      <span className="fa fa-lock"></span>
                      <input
                        type="password"
                        id="senha"
                        onChange={(e) => (this.senha = e.target.value)}
                        placeholder="Senha"
                        required
                      />{" "}
                    </p>

                    <div>
                      <span
                        style={{
                          width: "50%",
                          textalign: "right",
                          display: "inline-block",
                        }}
                      >
                        <input type="submit" value="Acesso" />
                      </span>
                    </div>
                  </fieldset>
                  <div className="clearfix_login"></div>
                </form>
              </div>
            </div>
          </center>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import "../../styles/styles.css";
import { Link } from "react-router-dom";
import { mask_data } from "../../Plugins/Mascara/Mascara";
import { api, handleInfo } from "../../Plugins/Config/Config";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solicitacoes: [],
      dataini: "",
      datafim: "",
    };
    this.FiltrarCampos = this.FiltrarCampos.bind(this);
  }

  atualizar_GET(data1, data2) {
    api
      .get(
        "sicoob/solicitacao/data/" +
          localStorage.getItem("agencia") +
          "/" +
          data1 +
          "/" +
          data2,
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != "") {
          this.setState({ solicitacoes: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  FiltrarDatas = () => {
    const datafim = this.state.datafim;
    const dataini = this.state.dataini;

    api
      .get(
        "sicoob/solicitacao/data/" +
          localStorage.getItem("agencia") +
          "/" +
          dataini +
          "/" +
          datafim,
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data.length > 0) {
          this.setState({ solicitacoes: response.data });
        } else {
          const solicitacoes = [];
          this.setState({ solicitacoes });
          handleInfo("Nenhuma solicitação encontrada neste período");
        }
      })
      .catch((error) => {
        const solicitacoes = [];
        this.setState({ solicitacoes });
        handleInfo("Nenhuma solicitação encontrada neste período");
        console.log(error);
      });
  };

  FiltrarCampos() {
    var input, filter, table, tr, td, i;
    input = document.getElementById("filtro");
    filter = input.value.toUpperCase();
    table = document.getElementById("liberacao");
    tr = table.getElementsByTagName("tr");
    for (var i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (var j = 0; j < tds.length - 2; j++) {
        var td = tds[j];

        if (td.textContent.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  StatusFinal(status, diretor1, usuario_atual_logado) {
    if (status.indexOf("P") != -1) {
      if (diretor1 == usuario_atual_logado) {
        return "PI";
      } else {
        return "P";
      }
    } else if (status.indexOf("D") != -1) {
      return "D";
    } else if (status.indexOf("R") != -1) {
      return "R";
    } else if (status == "AA") {
      return "A";
    }
  }

  ArrumarData(campo) {
    if (campo.length < 3) {
      return "0" + campo;
    } else {
      return campo;
    }
  }
  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    this.setState({ model });
    console.log(JSON.stringify(model));
  };

  componentDidMount = () => {
    var data = new Date();

    let data_atual = mask_data(data.toLocaleDateString());

    data.setDate(data.getDate() - 30);

    let menosdias = mask_data(data.toLocaleDateString());

    this.setState({ dataini: menosdias });
    this.setState({ datafim: data_atual });

    this.atualizar_GET(menosdias, data_atual);
  };
  render() {
    const { solicitacoes } = this.state;

    return (
      <>
        <div className="container" style={{ marginBottom: "50px" }}>
          <div
            className="modal fade"
            id="solicitacao"
            style={{ marginTop: "50px" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="solicitacao"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-dark" id="exampleModalLabel">
                    Solicitação
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="retorno"
            style={{ marginTop: "50px" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="retorno"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-dark" id="exampleModalLabel">
                    Retorno
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="shadow-lg  mb-5 bg-white rounded card">
            <div class="card-header">
              <h3 className="font-weight-bold text-center">Liberação</h3>
            </div>
            <div class="card-body">
              <form style={{ marginTop: "30px", marginBottom: "30px" }}>
                <div className="form-row">
                  <div className="col-md-4 ">
                    <label htmlFor="filtro">Filtrar Tabela:</label>
                    <input
                      id="filtro"
                      className="form-control"
                      onChange={(e) => this.FiltrarCampos()}
                      required="required"
                      type="text"
                      placeholder="Termo..."
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="dataini">Data Inicial:</label>
                    <input
                      className="form-control"
                      value={this.state.dataini}
                      onChange={(e) =>
                        this.setState({ dataini: e.target.value })
                      }
                      id="dataini"
                      required="required"
                      type="date"
                    />
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="datafim">Data Final:</label>
                    <input
                      id="datafim"
                      required="required"
                      value={this.state.datafim}
                      onChange={(e) =>
                        this.setState({ datafim: e.target.value })
                      }
                      className="form-control"
                      type="date"
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      type="button"
                      title="Cadastrar"
                      className="btn btn-primary"
                      onClick={(e) => this.FiltrarDatas()}
                      style={{ marginTop: "16%" }}
                    >
                      Filtrar Datas
                    </button>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </form>
              <div className="table-responsive">
                <table
                  className="table table-hover table-bordered text-center"
                  id="liberacao"
                  style={{ marginTop: "30px" }}
                >
                  <thead className="thead-dark">
                    <tr style={{ display: "table-row" }}>
                      <th scope="col">Aprovação</th>
                      <th scope="col">Solicitante</th>
                      <th scope="col">Grupo</th>
                      <th scope="col">SubGrupo</th>
                      <th scope="col">Data</th>
                      <th scope="col">Detalhes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitacoes.map(
                      function (solicitacao, i) {
                        var status =
                          solicitacao.sol_status1 + solicitacao.sol_status2;
                        // console.log(status);
                        var status_final = this.StatusFinal(
                          status,
                          solicitacao.autorizacao1,
                          localStorage.getItem("nome")
                        );

                        switch (status_final) {
                          case "PI":
                            var classe = "btn btn-secondary ";
                            var status = "Pendente";
                            break;
                          case "P":
                            var classe = "btn btn-primary ";
                            var status = "Pendente";
                            break;
                          case "A":
                            var classe = "btn btn-success ";
                            var status = "Aprovado";
                            break;
                          case "R":
                            var classe = "btn btn-danger ";
                            var status = "Reprovado";
                            break;
                          case "D":
                            var classe = "btn btn-warning ";
                            var status = "Devolução";
                            break;
                        }

                        return (
                          <tr key={solicitacao.sol_id}>
                            {localStorage.getItem("supervisor") > 0 ? (
                              <td>
                                <Link to={`/aprovacao/${solicitacao.sol_id}`}>
                                  <button
                                    style={{ borderRadius: "10px" }}
                                    type="button"
                                    title="Pendente"
                                    className={classe}
                                  >
                                    {status}
                                  </button>
                                </Link>{" "}
                              </td>
                            ) : (
                              <td>
                                <button
                                  style={{ borderRadius: "10px" }}
                                  type="button"
                                  title="Pendente"
                                  className={classe}
                                >
                                  {status}
                                </button>
                              </td>
                            )}
                            <td>{solicitacao.solicitante}</td>
                            <td>{solicitacao.sg_descricao}</td>
                            <td>{solicitacao.ssg_descricao}</td>
                            <td>
                              {mask_data(solicitacao.sol_data) +
                                " " +
                                solicitacao.sol_hora}
                            </td>
                            <td>
                              <Link
                                to={`/solicitacao_datalhes/${solicitacao.sol_id}`}
                              >
                                <button
                                  style={{ borderRadius: "10px" }}
                                  type="button"
                                  title="Detalhes"
                                  className="btn btn-primary"
                                >
                                  <i className="fas fa-file-alt"></i>
                                </button>
                              </Link>{" "}
                            </td>
                          </tr>
                        );
                      }.bind(this)
                    )}
                  </tbody>
                </table>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  }
}
export default Home;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { api, handleError, handleSuccess } from "../../Plugins/Config/Config";
import "../../styles/pre_loader.css";
import preloader from "../../../loading.gif";
import excel from "../../../imagens/svg/excel.png";
import imagem from "../../../imagens/svg/imagem.png";
import word from "../../../imagens/svg/word.png";
import pdf from "../../../imagens/svg/pdf.png";

class Solicitacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Solicitacao: [],
      model: {
        sol_parecer1: "",
        sol_parecer2: "",
        senha1: "",
        senha2: "",
        sol_parecer2: "",
        sol_parecer1: "",
        id: "",
        preloader: false,
      },
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    this.setState({ model });
  };

  Aprovacao = (tipo, e) => {
    const { model } = this.state;

    if (model.senha1) {
      if (model.senha1 !== localStorage.getItem("senha")) {
        handleError("Senha invalida");
        return false;
      }
      var concat_link = 1;
      var data = {
        sol_autorizacao1login: parseInt(localStorage.getItem("id")),
        sol_parecer1: model.sol_parecer1,
        sol_id: parseInt(model.id),
      };
    } else {
      if (model.senha2 !== localStorage.getItem("senha")) {
        handleError("Senha invalida");
        return false;
      }
      var concat_link = 2;
      var data = {
        sol_autorizacao2login: parseInt(localStorage.getItem("id")),
        sol_parecer2: model.sol_parecer2,
        sol_id: parseInt(model.id),
      };
    }
    if (tipo == "R") {
      var url = "sicoob/solicitacao/reprova";
    } else if (tipo == "A") {
      var url = "sicoob/solicitacao/aprova";
    } else if (tipo == "D") {
      var url = "sicoob/solicitacao/devolve";
    }

    api({
      method: "put",
      url: url + concat_link,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);
        this.props.history.push("/prototipo");
      })
      .catch((error) => {
        console.log(error);
        handleError(error.response.data.message);
        return false;
      });
  };

  ValidaAprovacao() {
    const { Solicitacao } = this.state;
    let status_style_1 = "";
    let status_style_2 = "";

    switch (Solicitacao.sol_status1) {
      case "A":
        status_style_1 = '<i style="color: #22e022;" class="fas fa-check"></i>';
        break;
      case "R":
        status_style_1 = '<i style="color: #ff0101;" class="fas fa-times"></i>';
        break;
      case "D":
        status_style_1 =
          '<i style="color: #ff0101;" class="fas fa-undo-alt"></i>';
        break;
    }

    switch (Solicitacao.sol_status2) {
      case "A":
        status_style_2 = '<i style="color: #22e022;" class="fas fa-check"></i>';
        break;
      case "R":
        status_style_2 = '<i style="color: #ff0101;" class="fas fa-times"></i>';
        break;
      case "D":
        status_style_2 =
          '<i style="color: #ff0101;" class="fas fa-undo-alt"></i>';
        break;
    }

    if (
      Solicitacao.autorizacao1 !== null ||
      document.getElementById("nav-home-tab").textContent !== ""
    ) {
      document.getElementById("nav-home-tab").innerHTML =
        status_style_1 + " Diretor: " + Solicitacao.autorizacao1;
      document.getElementById("sol_parecer1").disabled = true;
      document.getElementById("sol_parecer1").textContent =
        Solicitacao.sol_parecer1;
    } else {
      document.getElementById("nav-home-tab").textContent =
        "Diretor: Pendente ";
      document.getElementById("sol_parecer2").disabled = true;
      document.getElementById("sol_parecer1").disabled = true;
      document.getElementById("sol_parecer2").textContent =
        Solicitacao.sol_parecer2;
    }

    if (
      Solicitacao.autorizacao2 == null &&
      document.getElementById("nav-home-tab").textContent.length > 12
    ) {
      document.getElementById("nav-profile-tab").textContent =
        "Diretor: Pendente ";
    } else {
      document.getElementById("nav-profile-tab").innerHTML =
        status_style_2 + " Diretor: " + Solicitacao.autorizacao2;

      document.getElementById("sol_parecer2").textContent =
        Solicitacao.sol_parecer2;
      document.getElementById("sol_parecer2").disabled = true;
      document.getElementById("sol_parecer1").disabled = true;
    }
    if (
      Solicitacao.autorizacao1 !== null &&
      Solicitacao.autorizacao2 == null &&
      document.getElementById("nav-home-tab").textContent !==
        " Diretor: " + localStorage.getItem("nome")
    ) {
      document.getElementById("nav-profile-tab").textContent =
        "Diretor: Pendente ";
      document.getElementById("sol_parecer2").disabled = true;
      document.getElementById("sol_parecer1").disabled = true;
    }
    /*  if(Solicitacao.autorizacao2 !== null || document.getElementById('nav-home-tab').textContent !== '' ){
                 document.getElementById('nav-profile-tab').innerHTML = status_style_2+' Diretor: '+Solicitacao.autorizacao2; 
                 document.getElementById('sol_parecer2').disabled = true;
                 document.getElementById('sol_parecer2').textContent = Solicitacao.sol_parecer2;
                 document.getElementById('senha2').disabled = true
             }else if(Solicitacao.autorizacao2 !== null &&  document.getElementById('nav-home-tab').textContent.length > 12 ) {
                // document.getElementById('nav-profile-tab').innerHTML = status_style_2+' Diretor: '+localStorage.getItem("nome");
                 document.getElementById('nav-profile-tab').textContent = 'Diretor: Pendente ';    
                
                 alert(document.getElementById('nav-home-tab').textContent+' Diretor: '+localStorage.getItem('nome'));
             }*/
  }

  componentDidMount = () => {
    this.setState({ preloader: true });
    const { sol_id } = this.props.match.params;
    this.setState({ model: { id: sol_id } });
    api
      .get("sicoob/solicitacao/id/" + sol_id, {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        if (response.data != "") {
          this.setState({ Solicitacao: response.data[0] });
          this.ValidaAprovacao();
          this.setState({ preloader: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ preloader: false });
      });
  };

  Carregar_anexos() {
    const { Solicitacao } = this.state;
    var anexos_list = this.Criar_anexos(
      Solicitacao.sol_anexo1tipo,
      Solicitacao.sol_anexo1,
      Solicitacao.sol_anexo1nome,
      1
    );
    anexos_list += this.Criar_anexos(
      Solicitacao.sol_anexo2tipo,
      Solicitacao.sol_anexo2,
      Solicitacao.sol_anexo2nome,
      2
    );
    anexos_list += this.Criar_anexos(
      Solicitacao.sol_anexo3tipo,
      Solicitacao.sol_anexo3,
      Solicitacao.sol_anexo3nome,
      3
    );
    if (
      Solicitacao.sol_anexo1tipo !== "" &&
      Solicitacao.sol_anexo2tipo !== "" &&
      Solicitacao.sol_anexo3tipo !== ""
    ) {
      document.getElementById("anexos-list").innerHTML =
        anexos_list +
        '<span data-toggle="modal" style="color:blue;margin-left: -2px" data-target="#maisdetalhes"><b> + Mais Detalhes</b></span>';
    } else {
      document.getElementById("anexos-list").innerHTML =
        "<span>Nenhum anexo disponível</span>";
    }
  }
  Criar_anexos(extensao, data_uri, filename, numero) {
    if (extensao === "application/pdf") {
      return (
        '<a  href="' +
        data_uri +
        '" title="' +
        filename +
        '" download="' +
        filename +
        '"><div style="background-color: #e2574c"   class="fileUpload btn-svg btn  btn-orange">' +
        '<img style="margin-left: -2px"  src="' +
        pdf +
        '"/>' +
        '<span class="anexo"  >Anexo ' +
        numero +
        "</span></div></a>"
      );
    } else if (
      extensao === "application/vnd.ms-excel" ||
      extensao ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return (
        '<a  href="' +
        data_uri +
        '" title="' +
        filename +
        '" download="' +
        filename +
        '"><div style="background-color: #2e7d32"  class="fileUpload btn-svg btn  btn-orange">' +
        '<img style="margin-left: -2px"  src="' +
        excel +
        '"/>' +
        '<span class="anexo"  >Anexo ' +
        numero +
        "</span></div></a>"
      );
    } else if (
      extensao === "application/msword" ||
      extensao ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return (
        '<a  href="' +
        data_uri +
        '" title="' +
        filename +
        '" download="' +
        filename +
        '"><div style="background-color: #1565c0"  class="fileUpload btn-svg btn  btn-orange">' +
        '<img style="margin-left: -2px"  src="' +
        word +
        '"/>' +
        '<span class="anexo"  >Anexo ' +
        numero +
        "</span></div></a>"
      );
    } else if (extensao !== "") {
      return (
        '<a  href="' +
        data_uri +
        '" title="' +
        filename +
        '" download="' +
        filename +
        '"><div style="background-color: #656d78"  class="fileUpload btn-svg btn  btn-orange">' +
        '<img style="margin-left: -2px"  src="' +
        imagem +
        '"/>' +
        '<span class="anexo"  >Anexo ' +
        numero +
        "</span></div></a>"
      );
    } else {
      return "";
    }
  }

  render() {
    const { Solicitacao } = this.state;

    // console.log(JSON.stringify(Solicitacao));

    return (
      <>
        <div className="container" style={{ marginBottom: "50px" }}>
          <div
            className="modal fade"
            id="maisdetalhes"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="maisdetalhesTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-dark"
                    id="maisdetalhesLongTitle"
                  >
                    Nome dos Arquivos
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="list-group">
                    {Solicitacao.sol_anexo1nome !== "" ? (
                      <li className="list-group-item ">
                        <b>anexo 1:</b> {Solicitacao.sol_anexo1nome}
                      </li>
                    ) : (
                      ""
                    )}
                    {Solicitacao.sol_anexo2nome !== "" ? (
                      <li className="list-group-item ">
                        <b>anexo 2:</b> {Solicitacao.sol_anexo2nome}
                      </li>
                    ) : (
                      ""
                    )}
                    {Solicitacao.sol_anexo3nome !== "" ? (
                      <li className="list-group-item ">
                        <b>anexo 3:</b> {Solicitacao.sol_anexo3nome}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.preloader ? (
            <div id="loading-wrapper">
              <div id="loading-text">
                <picture data-fileentryid="1138056">
                  <img src={preloader} width={"110px"} />
                </picture>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 ">
            <div class="shadow-lg  mb-5 bg-white rounded card">
              <div class="card-header">
                <h3 className="font-weight-bold text-center">Aprovação</h3>
              </div>
              <div class="card-body">
                <form style={{ marginTop: "30px" }}>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-4 ">
                        <label htmlFor="solicitante">
                          <b>Solicitante:</b>
                        </label>
                        <input
                          id="solicitante"
                          className="form-control"
                          readOnly
                          required="required"
                          value={Solicitacao.solicitante}
                          type="text"
                          placeholder=" "
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="sg_descricao">
                          <b>Grupo de solicitação:</b>
                        </label>
                        <input
                          id="sg_descricao"
                          className="form-control"
                          readOnly
                          value={Solicitacao.sg_descricao}
                          required="required"
                          type="text"
                          placeholder=" "
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="ssg_descricao">
                          <b>SubGrupo Solicitação:</b>
                        </label>
                        <input
                          id="ssg_descricao"
                          className="form-control"
                          value={Solicitacao.ssg_descricao}
                          readOnly
                          required="required"
                          type="text"
                          placeholder=" "
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <nav>
                          <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              className="nav-item nav-link active"
                              id="nav-solicitante_descricao-tab"
                              data-toggle="tab"
                              href="#nav-solicitante_descricao"
                              role="tab"
                              aria-controls="nav-solicitante_descricao"
                              aria-selected="true"
                            >
                              Descrição
                            </a>
                            <a
                              className="nav-item nav-link "
                              id="nav-anexo-tab"
                              data-toggle="tab"
                              href="#nav-anexo"
                              role="tab"
                              aria-controls="nav-anexo"
                              onClick={(e) => this.Carregar_anexos()}
                              aria-selected="false"
                            >
                              Anexos
                            </a>
                          </div>
                        </nav>

                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nav-solicitante_descricao"
                            role="tabpanel"
                            aria-labelledby="nav-solicitante_descricao-tab"
                          >
                            <textarea
                              className="form-control"
                              id="descricao_solicitante"
                              value={Solicitacao.sol_solicitacao}
                              readOnly
                              rows="6"
                            ></textarea>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-anexo"
                            role="tabpanel"
                            aria-labelledby="nav-anexo-tab"
                          >
                            <ul className="list-group">
                              <li
                                id="anexos-list"
                                className="list-group-item "
                              ></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <nav>
                          <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              className="nav-item nav-link active"
                              id="nav-home-tab"
                              data-toggle="tab"
                              href="#nav-home"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            ></a>
                            <a
                              className="nav-item nav-link"
                              id="nav-profile-tab"
                              data-toggle="tab"
                              href="#nav-profile"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            ></a>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <textarea
                              className="form-control"
                              value={this.state.model.sol_parecer1}
                              onChange={(e) =>
                                this.setValues(e, "sol_parecer1")
                              }
                              id="sol_parecer1"
                              rows="6"
                            ></textarea>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <textarea
                              className="form-control"
                              value={this.state.model.sol_parecer2}
                              onChange={(e) =>
                                this.setValues(e, "sol_parecer2")
                              }
                              id="sol_parecer2"
                              rows="6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <Link to={`/prototipo`}>
                      {" "}
                      <button type="button" className="btn btn-secondary">
                        {" "}
                        Fechar&nbsp;<i className="fas fa-sign-out-alt"></i>{" "}
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  }
}
export default Solicitacao;

import React from "react";
import logo from "../../logo.png";
import { Link } from "react-router-dom";

function Navbar() {
  /* CODIGO PARA FECHAR MENU RESPONSIVO AO CLICAR EM UM LINK */
  var el_array = document.getElementsByClassName("link");
  for (var el of el_array) {
    el.addEventListener(
      "click",
      function FecharMenuResponsivo() {
        var element = document.getElementById("navbarSupportedContent");
        element.classList.remove("show");
      },
      false
    );
  }

  var url_atual = window.location.href;
  var splits = url_atual.split("/");
  //alert(splits[3]);
  var menu = "";
  switch (splits[3]) {
    /*case 'prototipo':menu = 1;break;
    case 'cadastro':menu = 1;break;
    case 'solicitacao':menu = 1;break;
    case 'relatorios':menu = 1;break;
    case 'aprovacao':menu = 1;break;*/
    case "logout":
      menu = 3;
      break;
    case "":
      menu = 3;
      break;
    default:
      menu = 0;
  }

  if (menu == 0) {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light "
        style={{
          backgroundColor: "#003641",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Link className="navbar-brand ml-5 link" to="/home">
          <img src={logo} alt="logo" style={{ width: "150px" }} />
        </Link>
        <button
          style={{ color: "#fff" }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <i className="fas fa-bars" style={{ color: "fff" }} />
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {localStorage.getItem("supervisor") > 0 ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <b>Cadastros</b>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {localStorage.getItem("supervisor") > 0 ? (
                    <Link className="dropdown-item link  " to="/gerente">
                      <b>Gerente</b>&nbsp;
                      <i
                        style={{ color: "red" }}
                        className="fas fa-user-plus"
                      ></i>
                    </Link>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("supervisor") > 0 ? (
                    <Link className="dropdown-item link " to="/cliente">
                      <b>Cliente</b>&nbsp;
                      <i
                        style={{ color: "#BFD73E" }}
                        className="fas fa-user-plus"
                      ></i>
                    </Link>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("supervisor") > 0 ? (
                    <Link className="dropdown-item link" to="/objetivo">
                      <b>Objetivo de visita</b>&nbsp;
                      <i
                        style={{ color: "#26f900" }}
                        className="fas fa-cubes"
                      ></i>
                    </Link>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("supervisor") > 0 ? (
                    <Link className="dropdown-item link " to="/transferir">
                      <b>Transferir Cliente</b>&nbsp;
                      <i
                        style={{ color: "red" }}
                        className="fas fa-exchange-alt"
                      ></i>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            ) : (
              ""
            )}
            {localStorage.getItem("supervisor") < 1 ? (
              <li className="nav-item link">
                {" "}
                <Link
                  className="dropdown-item  text-white "
                  style={{ textDecoration: "none", backgroundColor: "#003641" }}
                  to="/cliente"
                >
                  <b>Cliente</b>&nbsp;
                  <i
                    style={{ color: "#BFD73E" }}
                    className="fas fa-user-plus"
                  ></i>
                </Link>
              </li>
            ) : (
              ""
            )}
            <li className="nav-item">
              <Link
                className="dropdown-item text-white link "
                style={{ textDecoration: "none", backgroundColor: "#003641" }}
                to="/relatorio"
              >
                <b>Relatório</b>&nbsp;
                <i
                  style={{ color: "orange" }}
                  className="fas fa-address-card"
                ></i>
              </Link>
            </li>

            <li className="nav-item link">
              <Link
                className="dropdown-item text-white "
                style={{ textDecoration: "none", backgroundColor: "#003641" }}
                to="/rastreamento"
              >
                <b>Rastreamento</b>&nbsp;
                <i
                  style={{ color: "#00dcff" }}
                  className="fas fa-map-marker-alt"
                ></i>
              </Link>
            </li>

            <li className="nav-item link">
              <Link
                className="dropdown-item text-white "
                style={{ textDecoration: "none", backgroundColor: "#003641" }}
                to="/eventos"
              >
                <b>Eventos</b>&nbsp;
                <i
                  style={{ color: "#6eff00" }}
                  className="far fa-calendar-alt"
                ></i>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                className="dropdown-item  text-white  link"
                onClick={(e) =>
                  document
                    .getElementById("navbarSupportedContent")
                    .classList.toggle("navbarSupportedContent")
                }
                style={{ textDecoration: "none", backgroundColor: "#003641" }}
                to="/alterarsenha"
              >
                <b>Alterar Senha</b>&nbsp;
                <i
                  style={{ color: "#ffd400" }}
                  className="fas fa-user-edit"
                ></i>
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white menu_dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <b>Solicitação</b>
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item link " to="/prototipo">
                  <b>Liberação</b>&nbsp;
                  <i style={{ color: "red" }} className="fas fa-user-plus"></i>
                </Link>

                {localStorage.getItem("supervisor") > 0 ? (
                  <Link className="dropdown-item  link " to="/cadastro">
                    <b>Cadastro</b>&nbsp;
                    <i
                      style={{ color: "#BFD73E" }}
                      className="fas fa-user-plus"
                    ></i>
                  </Link>
                ) : (
                  ""
                )}
                <Link className="dropdown-item  link" to="/solicitacao">
                  <b>Solicitação</b>&nbsp;
                  <i style={{ color: "#26f900" }} className="fas fa-cubes"></i>
                </Link>
              </div>
            </li>
            {
              // <li className="nav-item"><Link className="dropdown-item text-white " style={{textDecoration:'none',backgroundColor:'#003641'}} to="/prototipo"><b>Solicitação</b>&nbsp;<i style={{color:'yellow'}} className="fas fa-angle-double-right"></i></Link></li>
            }
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <Link
              to="/logout"
              className="font-weight-bold  my-2 my-sm-0"
              style={{ color: "white", textDecoration: "none" }}
            >
              {localStorage.getItem("nome") + " "}
              <span style={{ color: "red" }}>(Sair)</span>
            </Link>
          </form>
        </div>
      </nav>
    );
  } else if (menu == 1) {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light  "
        style={{
          backgroundColor: "#003641",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Link className="navbar-brand ml-5" to="/home">
          <img src={logo} alt="logo" style={{ width: "150px" }} />
        </Link>
        <button
          style={{ color: "#fff" }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <i className="fas fa-bars" style={{ color: "fff" }} />
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link
                className="nav-link text-white   "
                style={{ padding: "0.3rem 1rem" }}
                to="/prototipo"
              >
                <b>Liberação</b>&nbsp;
                <i style={{ color: "#26f900" }} className="fas fa-desktop"></i>{" "}
                <span className="sr-only">(current)</span>
              </Link>
            </li>

            {localStorage.getItem("supervisor") > 0 ? (
              <li className="nav-item">
                <Link
                  className="dropdown-item text-white "
                  style={{ textDecoration: "none", backgroundColor: "#003641" }}
                  to="/cadastro"
                >
                  <b>Cadastro</b>&nbsp;
                  <i style={{ color: "red" }} className="fas fa-cogs"></i>
                </Link>
              </li>
            ) : (
              ""
            )}
            {localStorage.getItem("supervisor") > 0 ? (
              <li className="nav-item">
                <Link
                  className="dropdown-item text-white "
                  style={{ textDecoration: "none", backgroundColor: "#003641" }}
                  to="/solicitacao"
                >
                  <b>Solicitação</b>&nbsp;
                  <i style={{ color: "#26f900" }} className="fas fa-cubes"></i>
                </Link>
              </li>
            ) : (
              ""
            )}

            <li className="nav-item">
              <Link
                className="dropdown-item text-white "
                style={{ textDecoration: "none", backgroundColor: "#003641" }}
                to="/home"
              >
                <b>Voltar</b>&nbsp;
                <i
                  style={{ color: "yellow" }}
                  className="fas fa-angle-double-left"
                ></i>
              </Link>
            </li>
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <Link
              to="/logout"
              className="font-weight-bold  my-2 my-sm-0"
              style={{ color: "white", textDecoration: "none" }}
            >
              {localStorage.getItem("nome") + " "}
              <span style={{ color: "red" }}>(Sair)</span>
            </Link>
          </form>
        </div>
      </nav>
    );
  } else if (menu == 3) {
    return <div></div>;
  }
}
export default Navbar;

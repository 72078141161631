import React, { Component } from "react";

import { ToastContainer } from "react-toastify";

import PrivateRoute from "./auth";
// React Boostrap e outros  css Import
import GlobalStyle from "./components/styles";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.slim.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// React Router Import
import { Switch, Route, BrowserRouter } from "react-router-dom";
// React Menu e Footer Import
import Navbar from "./components/layouts/NavBar";
import Footer from "./components/layouts/Footer";
// Import Pages
import Home from "./components/pages/Home";
import Cliente from "./components/Contas/Cliente";
import Visita from "./components/pages/Visita";
import Relatorio from "./components/pages/Relatorio";
import Gerente from "./components/Contas/Gerente";
import AlterarSenha from "./components/Contas/AlterarSenha";
import Objetivo from "./components/pages/Objetivo";
import Rastreamento from "./components/pages/Rastreamento";
import Eventos from "./components/pages/Eventos";
import Transferir from "./components/pages/Transferir";
import Cad_visita from "./components/pages/Cad_visita";
import Error404 from "./components/pages/Error404";
import Login from "./components/Login/Login";
import Logout from "./components/Login/Logout";
// Import aprovacao
import Homev2 from "./components/pages/aprovacao/Home";
import Cadastro from "./components/pages/aprovacao/Cadastro";
import Aprovacao from "./components/pages/aprovacao/Aprovacao";
import Solicitacao_datalhes from "./components/pages/aprovacao/Solicitacao_datalhes";
import Solicitacao from "./components/pages/aprovacao/Solicitacao";

import "./App.css";
class App extends Component {
  render() {
    const Layout = ({ children }) => (
      <div>
        <Navbar />
        <Switch>{children}</Switch>
        <Footer />
      </div>
    );

    return (
      <div>
        <GlobalStyle />
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Layout>
              <PrivateRoute path="/prototipo" component={Homev2} />
              <PrivateRoute path="/cadastro" component={Cadastro} />

              <PrivateRoute path="/solicitacao" component={Solicitacao} />
              <PrivateRoute path="/aprovacao/:sol_id" component={Aprovacao} />
              <PrivateRoute
                path="/solicitacao_datalhes/:sol_id"
                component={Solicitacao_datalhes}
              />
              <PrivateRoute path="/home" component={Home} />
              <PrivateRoute path="/cliente" component={Cliente} />
              <PrivateRoute path="/gerente" component={Gerente} />
              <PrivateRoute path="/objetivo" component={Objetivo} />
              <PrivateRoute path="/alterarsenha" component={AlterarSenha} />
              <PrivateRoute path="/visita/:cli_codigo" component={Visita} />
              <PrivateRoute
                path="/cad_visita/:cli_codigo/:cli_nome"
                component={Cad_visita}
              />
              <PrivateRoute path="/relatorio" component={Relatorio} />
              <PrivateRoute path="/rastreamento" component={Rastreamento} />
              <PrivateRoute path="/eventos" component={Eventos} />
              <PrivateRoute path="/transferir" component={Transferir} />
              <PrivateRoute path="*" component={Error404} />
            </Layout>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

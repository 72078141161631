import React, { Component } from "react";
import Select from "react-select";
import { api, handleError, handleSuccess } from "../../Plugins/Config/Config";
import * as XLSX from "xlsx";
import "../../styles/pre_loader.css";
import preloader from "../../../loading.gif";
import word from "../../../imagens/svg/word.png";
import pdf from "../../../imagens/svg/pdf.png";
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
const filterIsNaN = (value) => {
  if (typeof value === "undefined") {
    return "";
  } else {
    return value;
  }
};

const PegarExtensaoDoNome = (uri) => {
  if (uri) {
    let uri_tamanho = uri.length;
    uri = uri.substring(uri_tamanho - 5, uri_tamanho);
    let result = uri.split(".");
    return result[1];
    debugger;
  }
};

class Solicitacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGrupo: null,
      statusGrupo: [],
      selectedSubGrupo: null,
      statusSubGrupo: [],
      preloader: false,
      model: {
        sol_id: 0,
        grupo: "",
        grupo_label: "",
        subgrupo: "",
        subgrupo_label: "",
        solicitante: "",
        descricao: "",
      },
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }
  GerarDataURI(file, i) {
    if (file) {
      let readerb = new FileReader();
      readerb.onload = (e) => {
        switch (i) {
          case 0:
            document.getElementById("anexo" + i).dataset.filename0 = file.name;
            break;
          case 1:
            document.getElementById("anexo" + i).dataset.filename1 = file.name;
            break;
          case 2:
            document.getElementById("anexo" + i).dataset.filename2 = file.name;
            break;
        }
        document.getElementById("anexo" + i).dataset.filename0 = file.name;
        document.getElementById("anexo" + i).value = e.target.result;
      };
      readerb.readAsDataURL(file);
    }

    // Read in the image file as a data URL.
  }

  _handleImageChange(e) {
    e.preventDefault();

    document.getElementById("pdf").innerHTML = "";
    document.getElementById("list").innerHTML = "";
    document.getElementById("atributos1").innerHTML = "";
    document.getElementById("atributos2").innerHTML = "";
    document.getElementById("atributos3").innerHTML = "";
    var fileInput = document.getElementById("files");
    var fileList = [];

    if (fileInput.files.length > 3) {
      handleError("No máximo 3 arquivos como anexo");
      document.getElementById("files").value = "";
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      this.GerarDataURI(fileList[i], i);

      // Only process image files.
      if (
        !f.type.match("image.*") &&
        !f.type.match(".pdf") &&
        PegarExtensaoDoNome(f.name) !== "doc" &&
        PegarExtensaoDoNome(f.name) !== "docx"
      ) {
        continue;
      }

      var reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = (function (theFile) {
        //  console.log(PegarExtensaoDoNome(JSON.stringify(theFile)));
        return function (e) {
          // Render thumbnail.

          if (theFile.name.match(".pdf")) {
            var PDFspan = document.createElement("span");
            PDFspan.innerHTML = [
              '<div class="fileUpload  btn btn-orange">' +
                '<img src="' +
                pdf +
                '" class="icon">' +
                '<span class="upl" id="upload">  ' +
                theFile.name +
                "</span></div>",
            ].join("");
            document.getElementById("pdf").insertBefore(PDFspan, null);
          } else if (
            PegarExtensaoDoNome(theFile.name) === "doc" ||
            PegarExtensaoDoNome(theFile.name) === "docx"
          ) {
            var PDFspan = document.createElement("span");
            PDFspan.innerHTML = [
              '<div class="fileUpload  btn btn-orange">' +
                '<img src="' +
                word +
                '" class="icon">' +
                '<span class="upl" id="upload">  ' +
                theFile.name +
                "</span></div>",
            ].join("");
            document.getElementById("pdf").insertBefore(PDFspan, null);
          } else {
            var span = document.createElement("span");
            span.innerHTML = [
              '<img class="thumb-upload" src="',
              e.target.result,
              '" title="',
              escape(theFile.name),
              '"/>',
            ].join("");
            document.getElementById("list").insertBefore(span, null);
          }
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }

    if (
      fileList[0] &&
      !fileList[0].type.match("image.*") &&
      !fileList[0].type.match(".pdf") &&
      PegarExtensaoDoNome(fileList[0].name) !== "doc" &&
      PegarExtensaoDoNome(fileList[0].name) !== "docx"
    ) {
      (function (file) {
        let reader = new FileReader();
        let rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;

          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          let data0 = XLSX.utils.sheet_to_json(ws, { header: 1 });
          let cols0 = make_cols(ws["!ref"]);

          let tabela =
            '<h2 style="text-align: center;"><b>' +
            file.name +
            "</b></h2>" +
            '<div class="table-responsive" style="margin-bottom: 30px;">' +
            '<table class="table table-hover table-bordered text-center">' +
            '<thead class="thead-dark">' +
            " <tr >";
          for (var i = 0; i < cols0.length; i++) {
            tabela +=
              '<th style="background-color:#4CAF50;">' +
              cols0[i].name +
              "</th>";
          }
          tabela += "</tr>" + " </thead>" + "<tbody>";
          for (var j = 0; j < data0.length; j++) {
            tabela += "<tr>";
            for (var k = 0; k < cols0.length; k++) {
              tabela += "<td>" + filterIsNaN(data0[j][k]) + "</td>";
            }
            tabela += "</tr>";
          }
          tabela += " </tbody>" + " </table>" + "</div>";
          document.getElementById("atributos1").innerHTML = tabela;
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      })(fileList[0]);
    }
    if (
      fileList[1] &&
      !fileList[1].type.match("image.*") &&
      !fileList[1].type.match(".pdf") &&
      PegarExtensaoDoNome(fileList[1].name) !== "doc" &&
      PegarExtensaoDoNome(fileList[1].name) !== "docx"
    ) {
      (function (file) {
        let reader = new FileReader();
        let rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;

          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          var data1 = XLSX.utils.sheet_to_json(ws, { header: 1 });
          var cols1 = make_cols(ws["!ref"]);

          let tabela =
            '<h2 style="text-align: center;"><b>' +
            file.name +
            "</b></h2>" +
            '<div class="table-responsive" style="margin-bottom: 30px;">' +
            '<table class="table table-hover table-bordered text-center">' +
            '<thead class="thead-dark">' +
            " <tr>";
          for (var i = 0; i < cols1.length; i++) {
            tabela +=
              '<th style="background-color:#4CAF50;">' +
              cols1[i].name +
              "</th>";
          }
          tabela += "</tr>" + " </thead>" + "<tbody>";
          for (var j = 0; j < data1.length; j++) {
            tabela += "<tr>";
            for (var k = 0; k < cols1.length; k++) {
              tabela += "<td >" + filterIsNaN(data1[j][k]) + "</td>";
            }
            tabela += "</tr>";
          }
          tabela += " </tbody>" + " </table>" + "</div>";
          document.getElementById("atributos2").innerHTML = tabela;
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      })(fileList[1]);
    }
    if (
      fileList[2] &&
      !fileList[2].type.match("image.*") &&
      !fileList[2].type.match(".pdf") &&
      PegarExtensaoDoNome(fileList[2].name) !== "doc" &&
      PegarExtensaoDoNome(fileList[2].name) !== "docx"
    ) {
      (function (file) {
        let reader = new FileReader();
        let rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;

          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          var data2 = XLSX.utils.sheet_to_json(ws, { header: 1 });
          var cols2 = make_cols(ws["!ref"]);

          let tabela =
            '<h2 style="text-align: center;"><b>' +
            file.name +
            "</b></h2>" +
            '<div class="table-responsive" style="margin-bottom: 30px;">' +
            '<table class="table table-hover table-bordered text-center">' +
            '<thead class="thead-dark">' +
            " <tr>";
          for (var i = 0; i < cols2.length; i++) {
            tabela +=
              '<th style="background-color:#4CAF50;">' +
              cols2[i].name +
              "</th>";
          }
          tabela += "</tr>" + " </thead>" + "<tbody>";
          for (var j = 0; j < data2.length; j++) {
            tabela += "<tr>";
            for (var k = 0; k < cols2.length; k++) {
              tabela += "<td >" + filterIsNaN(data2[j][k]) + "</td>";
            }
            tabela += "</tr>";
          }
          tabela += " </tbody>" + " </table>" + "</div>";
          document.getElementById("atributos3").innerHTML = tabela;
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      })(fileList[2]);
    }
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    this.setState({ model });
  };
  // funcao para alterar o valor do campo select
  handleChange = (selectedGrupo) => {
    const { model } = this.state; // declarando o state model para poder ser utilizado
    model.grupo = selectedGrupo.value; // atribuindo o valor da option selecionada ao state model equivalente ao campo
    model.grupo_label = selectedGrupo.label;
    this.setState({ model }); // salvando o  novo valor atribuido ao state em questao
    // console.log('valor do estado de selectedGrupo: '+ JSON.stringify(selectedGrupo.value));
    this.setState({ selectedGrupo }); // atualizando o state de  selectedGrupo
    this.setState({ selectedSubGrupo: { value: -1, label: "Carregando..." } });
    api
      .get("sicoob/solsubgrupo/" + localStorage.getItem("agencia") + "/A", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        const statusSubGrupo = [];
        const selectedSubGrupo = [];
        this.setState({ statusSubGrupo });
        this.setState({ selectedSubGrupo });
        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        for (var i = 0; i < response.data.length; i++) {
          if (selectedGrupo.value == response.data[i].ssg_grupo) {
            statusSubGrupo.push({
              value: response.data[i].ssg_id,
              label: response.data[i].ssg_descricao,
            });
          }
        }
        this.setState({ statusSubGrupo }); // salvando o novo state
      })
      .catch((error) => {
        const selectedSubGrupo = [];
        const statusSubGrupo = [];
        statusSubGrupo.push({
          value: "-10",
          label: "Nenhum SubGrupo cadastrado",
        });
        selectedSubGrupo.push({
          value: "-10",
          label: "Nenhum SubGrupo cadastrado",
        });
        model.subgrupo = "";
        model.subgrupo_label = "";
        this.setState({ model });
        // console.log('response de cliente é' + JSON.stringify('model do select' + model));
        this.setState({ statusSubGrupo }); // salvando o novo state
        this.setState({ selectedSubGrupo }); // salvando o novo state
      });
  };

  PegarExtensao(uri) {
    if (uri) {
      let result = uri.split(";");
      result = result[0].split(":");
      return result[1];
    }
  }
  handleChangecliente = (selectedSubGrupo) => {
    const { model } = this.state;
    model.subgrupo = selectedSubGrupo.value;
    model.subgrupo_label = selectedSubGrupo.label;

    this.setState({ model });
    this.setState({ selectedSubGrupo });
  };
  save = () => {
    this.setState({ preloader: true });
    const { model } = this.state;

    if (model.grupo == "" || model.subgrupo == "" || model.descricao == "") {
      handleError("Preencha todos os campos");
      this.setState({ preloader: false });
      return false;
    }

    var sol_anexo1tipo = this.PegarExtensao(
      document.getElementById("anexo0").value
    );
    var sol_anexo2tipo = this.PegarExtensao(
      document.getElementById("anexo1").value
    );
    var sol_anexo3tipo = this.PegarExtensao(
      document.getElementById("anexo2").value
    );

    var data = {
      sol_agencia: parseInt(localStorage.getItem("agencia")),
      sol_login: parseInt(localStorage.getItem("id")),
      sol_grupo: parseInt(model.grupo),
      sol_subgrupo: parseInt(model.subgrupo),
      sol_solicitacao: model.descricao,
      sol_documento: "",
      sol_anexo1tipo: sol_anexo1tipo,
      sol_anexo2tipo: sol_anexo2tipo,
      sol_anexo3tipo: sol_anexo3tipo,
      sol_anexo1nome: document.getElementById("anexo0").dataset.filename0,
      sol_anexo2nome: document.getElementById("anexo1").dataset.filename1,
      sol_anexo3nome: document.getElementById("anexo2").dataset.filename2,
      sol_anexo1: document.getElementById("anexo0").value,
      sol_anexo2: document.getElementById("anexo1").value,
      sol_anexo3: document.getElementById("anexo2").value,
    };
    //console.log(JSON.stringify(data));return false;
    api({
      method: "post",
      url: "sicoob/solicitacao",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        this.setState({
          model: {
            sol_id: 0,
            grupo: "",
            grupo_label: "",
            subgrupo: "",
            subgrupo_label: "",
            solicitante: "",
            descricao: "",
          },
        });
        this.setState({ selectedGrupo: null });
        this.setState({ selectedSubGrupo: null });
        document.getElementById("pdf").innerHTML = "";
        document.getElementById("list").innerHTML = "";
        document.getElementById("atributos1").innerHTML = "";
        document.getElementById("atributos2").innerHTML = "";
        document.getElementById("atributos3").innerHTML = "";
        this.setState({ preloader: false });
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ preloader: false });
        handleError(error.data.message);
        return false;
      });
  };

  componentDidMount = () => {
    document.getElementById("anexo0").dataset.filename0 = "";
    document.getElementById("anexo1").dataset.filename1 = "";
    document.getElementById("anexo2").dataset.filename2 = "";
    api
      .get("sicoob/solgrupo/" + localStorage.getItem("agencia") + "/A", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        const statusGrupo = [];

        for (var i = 0; i < response.data.length; i++) {
          statusGrupo.push({
            value: response.data[i].sg_id,
            label: response.data[i].sg_descricao,
          });
        }

        this.setState({ statusGrupo });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { selectedGrupo, statusGrupo } = this.state;
    const { selectedSubGrupo, statusSubGrupo } = this.state;

    return (
      <>
        <div className="container" style={{ marginBottom: "50px" }}>
          {this.state.preloader ? (
            <div id="loading-wrapper">
              <div id="loading-text">
                <picture data-fileentryid="1138056">
                  <img src={preloader} width={"110px"} />
                </picture>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 ">
            <div class="shadow-lg  mb-5 bg-white rounded card">
              <div class="card-header">
                <h3 className="font-weight-bold text-center">
                  Cadastro de Solicitação
                </h3>
              </div>
              <div class="card-body">
                <form
                  onSubmit={this._handleSubmit}
                  style={{ marginTop: "30px" }}
                >
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-4 ">
                        <label htmlFor="solicitante">
                          <b>Solicitante:</b>
                        </label>
                        <input
                          id="solicitante"
                          className="form-control"
                          readOnly
                          required="required"
                          value={localStorage.getItem("nome")}
                          type="text"
                          placeholder=" "
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="supervisor">
                          Grupo de Solicitação:
                        </label>
                        <Select
                          id="supervisor"
                          value={selectedGrupo}
                          onChange={this.handleChange}
                          options={statusGrupo}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="cliente">
                          SubGrupo de Solicitação:
                        </label>
                        <Select
                          id="cliente"
                          value={selectedSubGrupo}
                          onChange={this.handleChangecliente}
                          options={statusSubGrupo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="descricao">
                      <b>Descrição:</b>
                    </label>
                    <textarea
                      className="form-control"
                      id="descricao"
                      required="required"
                      type="text"
                      value={this.state.model.descricao}
                      onChange={(e) => this.setValues(e, "descricao")}
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="filess">
                          <b>Anexo:</b>
                        </label>
                        <br />
                        <input
                          type="file"
                          name="files[]"
                          style={{ backgroundColor: "#ecf0f4", border: "0px" }}
                          accept=".csv,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.pdf,image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          id="files"
                          multiple
                          onChange={this._handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.save}
                    >
                      {" "}
                      Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
                    </button>
                  </div>
                </form>
                <div id="pdf"></div>
                <output id="list"></output>
                <div id="atributos1"></div>
                <div id="atributos2"></div>
                <div id="atributos3"></div>
                <input data-filename0 id="anexo0" type="hidden" />
                <input data-filename1 id="anexo1" type="hidden" />
                <input data-filename2 id="anexo2" type="hidden" />
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  }
}
export default Solicitacao;

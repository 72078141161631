import React, { Component } from "react";
import PubSub from "pubsub-js";
import axios from "axios";
import { handleError, handleSuccess } from "../Plugins/Config/Config";

// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
class FormProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        usuario: localStorage.getItem("nome"),
        novasenha: "",
        repetirsenha: "",
      },
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    if (model.novasenha === model.repetirsenha && model.repetirsenha !== "") {
      document.getElementById("repetirsenha").style.color = "green";
      document.getElementById("repetirsenha").style.borderColor = "green";
      document.getElementById("repetirsenha").style.boxShadow =
        "0 0 0 0.2rem rgba(143, 189, 131, 0.25)";
    } else {
      document.getElementById("repetirsenha").style.color = "#495057";
      document.getElementById("repetirsenha").style.border =
        "1px solid #ced4da";
      document.getElementById("repetirsenha").style.boxShadow = "";
    }
    this.setState({ model });
  };

  create = () => {
    const { model } = this.state;

    if (
      model.usuario !== "" &&
      model.novasenha !== "" &&
      model.repetirsenha !== ""
    ) {
      if (model.novasenha === model.repetirsenha) {
        this.setState({
          model: { id: 0, nome: "", novasenha: "", repetirsenha: "" },
        });
        this.props.productCreate(this.state.model);
      } else {
        handleError("Repita a mesma senha para confirmar");
        document.getElementById("repetirsenha").style.color = "red";
        document.getElementById("repetirsenha").style.borderColor = "#f00";
        document.getElementById("repetirsenha").style.boxShadow =
          "rgba(189, 131, 131, 0.25) 0px 0px 0px 0.2rem";
        return false;
      }
    } else {
      handleError("Preencha todos campos");
      return false;
    }
  };

  // componentDidMount eh o metodo que eh executado assim que a pagina eh carregada ou seja equivale a um evento onready do jquery o load do js puro
  componentDidMount = () => {
    document.getElementById("usuario").disabled = true;
  };
  componentWillMount() {
    PubSub.subscribe("edit-product", (topic, product) => {
      //   console.log(JSON.stringify(product));

      this.setState({ model: product });
      this.setState({
        selectedOption: {
          value: product.supervisor,
          label: product.supervisor,
        },
      });
    });
  }

  render() {
    return (
      <>
        <form style={{ marginTop: "30px", marginBottom: "30px" }}>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <label htmlFor="usuario">Usuário:</label>
                <input
                  id="usuario"
                  className="form-control"
                  type="text"
                  value={this.state.model.usuario}
                  onChange={(e) => this.setValues(e, "usuario")}
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <label htmlFor="novasenha">Nova senha:</label>
                <input
                  id="novasenha"
                  className="form-control"
                  required="required"
                  type="text"
                  value={this.state.model.novasenha}
                  placeholder="Insira a nova senha"
                  onChange={(e) => this.setValues(e, "novasenha")}
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <label htmlFor="repetirsenha">Repita a nova senha:</label>
                <input
                  id="repetirsenha"
                  className="form-control"
                  required="required"
                  type="text"
                  value={this.state.model.repetirsenha}
                  placeholder="Repita a nova senha"
                  onChange={(e) => this.setValues(e, "repetirsenha")}
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-3"></div>
              <div className="col-md-6 text-center">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={this.create}
                >
                  {" "}
                  Alterar&nbsp;<i className="fas fa-edit"></i>{" "}
                </button>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default class ProductBox extends Component {
  // declando variaveis de states
  state = {
    products: [],

    message: {
      text: "",
      alert: "",
    },
  };

  componentDidMount() {}

  //metodo save realiza o envio dos dados para api para insercao e alteracao dos dados
  // abaixo esta passando os dados cadasrados no formulario para o array data
  save = (product) => {
    // EDIT PRODUCT
    axios({
      method: "put", //you can set what request you want to be
      url:
        "http://52.11.139.178/sicoob/login/trocasenha/" +
        localStorage.getItem("agencia") +
        "/" +
        localStorage.getItem("id") +
        "/" +
        product.novasenha,
      data: "",
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);

        document.getElementById("repetirsenha").style.color = "#495057";
        document.getElementById("repetirsenha").style.border =
          "1px solid #ced4da";
        document.getElementById("repetirsenha").style.boxShadow = "";
      })
      .catch((error) => {
        console.log(error);

        handleError(error.response.data.message);
        return false;
      });
  };

  // metodo responsavel pelas mensagens de sucesso e erro
  timerMessage = (duration) => {
    setTimeout(() => {
      this.setState({ message: { text: "", alert: "" } });
    }, duration);
  };

  render() {
    return (
      <div className="container" style={{ marginTop: "30px" }}>
        {
          this.state.message.text !== "" ? (
            <alert
              className="alert alert-danger text-center"
              role="alert"
              color={this.state.message.alert}
            >
              {" "}
              {this.state.message.text}{" "}
            </alert>
          ) : (
            ""
          )

          // abaixo eh visto o instanciamento dos componentes FormProduct e ListProduct
        }

        <div className="row">
          <div className="col-md-12 ">
            <div
              style={{ marginTop: "35px" }}
              class="shadow-lg  mb-5 bg-white rounded card"
            >
              <div class="card-header">
                <h3 className="font-weight-bold text-center">Alterar Senha</h3>
              </div>
              <div class="card-body">
                <FormProduct productCreate={this.save} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import PubSub from "pubsub-js";
import axios from "axios";
import { Link } from "react-router-dom";
import { mask_tel_celular_area, mask_data } from "../Plugins/Mascara/Mascara";
import Select from "react-select";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "../styles/pre_loader.css";
import preloader from "../../loading.gif";
import {
  handleError,
  handleSuccess,
  handleInfo,
} from "../Plugins/Config/Config";
class FormProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      model: {
        cli_idgerente: localStorage.getItem("id"),
        cli_nome: "",
        cli_endereco: "",
        cli_numero: "",
        cli_bairro: "",
        cli_cidade: "",
        cli_fone: "",
        cli_celular: "",
        cli_contato: "",
        cli_latitude: "",
        cli_longitude: "",
        municipio: "",
        inputValue: "",
        cli_codigo: 0,
      },
    };
  }

  validanull = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    if (model.cli_fone.length >= 14) {
      model.cli_fone = model.cli_fone;
      this.setState({ model });
    } else {
      model.cli_fone = "";
      this.setState({ model });
    }
    if (model.cli_celular.length >= 14) {
      model.cli_celular = model.cli_celular;
      this.setState({ model });
    } else {
      model.cli_celular = "";
      this.setState({ model });
    }
  };
  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;

    model.cli_fone = mask_tel_celular_area(model.cli_fone);
    model.cli_celular = mask_tel_celular_area(model.cli_celular);

    this.setState({ model });
  };
  create = (e) => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName("needs-validation");
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      if (e) {
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        form.classList.add("was-validated");
      }
    });

    const { model } = this.state;
    if (
      model.cli_nome !== "" &&
      model.cli_endereco !== "" &&
      model.cli_bairro !== "" &&
      model.cli_cidade !== "" &&
      model.cli_fone !== ""
    ) {
      this.setState({
        model: {
          cli_idgerente: localStorage.getItem("id"),
          municipio: "",
          cli_cidade: "",
          cli_nome: "",
          cli_endereco: "",
          cli_numero: "",
          cli_bairro: "",
          id: "",
          cli_fone: "",
          cli_celular: "",
          cli_latitude: "",
          cli_longitude: "",
          cli_codigo: 0,
          cli_contato: "",
          inputValue: "",
        },
      });
      this.setState({ selectedOption: null });
      this.props.productCreate(this.state.model);
    } else {
      handleError("Preencha todos campos");
      return false;
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue;
    this.setState({ inputValue });
    //  console.log(inputValue)
    var n = inputValue.length;
    if (n >= 3) {
      // console.log('foi');

      axios
        .get("http://52.11.139.178/sicoob/cidade/" + inputValue, {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
        .then((response) => {
          const statusOptions = [];
          // construindo o corpo das options do select com as variaves vindas da api atraves do axios
          for (var i = 0; i < response.data.length; i++) {
            statusOptions.push({
              value: response.data[i].id,
              label: response.data[i].municipio + " / " + response.data[i].uf,
            });
          }
          this.setState({ statusOptions }); // salvando o novo state
        })
        .catch((error) => {
          console.log(error);
          handleError(error.response.data.message);
        });
    }
  };
  handleChange = (selectedOption) => {
    const { model } = this.state; // declarando o state model para poder ser utilizado
    model.cli_cidade = selectedOption.value;
    model.municipio = selectedOption.label;
    this.setState({ model }); // salvando o  novo valor atribuido ao state em questao
    //  console.log('valor do estado de selectedOption: '+ JSON.stringify(selectedOption));
    this.setState({ selectedOption }); // atualizando o state de  selectedOption
  };

  componentWillMount() {
    PubSub.subscribe("edit-product", (topic, product) => {
      this.setState({ model: product });
      this.setState({
        selectedOption: { value: product.cli_cidade, label: product.municipio },
      });
      // console.log('model para alterar: '+ JSON.stringify(this.state.model));
    });
  }

  render() {
    const { selectedOption, statusOptions } = this.state;

    return (
      <>
        <form className="needs-validation">
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-4 ">
                <label htmlFor="cli_nome">Nome:</label>
                <input
                  id="cli_nome"
                  className="form-control"
                  required="required"
                  type="text"
                  value={this.state.model.cli_nome}
                  placeholder="Nome do cliente..."
                  onChange={(e) => this.setValues(e, "cli_nome")}
                />{" "}
                <div class="invalid-tooltip">Preencha o campo nome.</div>
              </div>

              <div className="col-md-5">
                <label htmlFor="cli_cli_enderecocidade">Endereço:</label>
                <input
                  id="cli_endereco"
                  className="form-control"
                  required="required"
                  type="text"
                  value={this.state.model.cli_endereco}
                  placeholder="Rua...."
                  onChange={(e) => this.setValues(e, "cli_endereco")}
                />{" "}
                <div class="invalid-tooltip">Preencha o campo endereço.</div>
              </div>
              <div className="col-md-1">
                <label htmlFor="cli_numero">Núrmero:</label>
                <input
                  id="cli_numero"
                  className="form-control"
                  type="text"
                  value={this.state.model.cli_numero}
                  placeholder=" n.º"
                  onChange={(e) => this.setValues(e, "cli_numero")}
                />{" "}
              </div>
              <div className="col-md-2">
                <label htmlFor="cli_bairro">Bairro:</label>
                <input
                  id="cli_bairro"
                  className="form-control"
                  required="required"
                  type="text"
                  value={this.state.model.cli_bairro}
                  placeholder="Bairro..."
                  onChange={(e) => this.setValues(e, "cli_bairro")}
                />{" "}
                <div class="invalid-tooltip">Preencha o campo bairro.</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-5">
                <label htmlFor="cli_cidade">Cidade:</label>
                <Select
                  style={{ zIndex: "999" }}
                  id="cli_cidade"
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={statusOptions}
                  onInputChange={this.handleInputChange}
                />{" "}
                <input
                  style={{ marginTop: "-37px", zIndex: "1" }}
                  id="cli_cidade_para_validar"
                  className="form-control"
                  required="required"
                  type="text"
                  value={selectedOption}
                />
                <div style={{ zIndex: "0" }} class="invalid-tooltip">
                  Preencha o campo cidade.
                </div>
              </div>
              <div className="col-md-2">
                <label htmlFor="cli_fone">Telefone:</label>
                <input
                  className="form-control"
                  required
                  id="cli_fone"
                  type="text"
                  onBlur={(e) => this.validanull(e, "cli_fone")}
                  value={this.state.model.cli_fone}
                  placeholder="Telefone..."
                  onChange={(e) => this.setValues(e, "cli_fone")}
                />{" "}
                <div class="invalid-tooltip">Preencha o campo telefone.</div>
              </div>

              <div className="col-md-3">
                <label htmlFor="cli_contato">Contato:</label>
                <input
                  id="cli_contato"
                  className="form-control"
                  type="text"
                  value={this.state.model.cli_contato}
                  placeholder="Nome do Contato..."
                  onChange={(e) => this.setValues(e, "cli_contato")}
                />{" "}
              </div>
              <div className="col-md-2">
                <label htmlFor="cli_celular">Contato Celular:</label>
                <input
                  className="form-control"
                  id="cli_celular"
                  type="text"
                  onBlur={(e) => this.validanull(e, "cli_celular")}
                  value={this.state.model.cli_celular}
                  placeholder="Celular..."
                  onChange={(e) => this.setValues(e, "cli_celular")}
                />{" "}
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => this.create(e)}
            >
              {" "}
              Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
            </button>
          </div>
        </form>
      </>
    );
  }
}

class ListProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pesquisar: {
        busca: "",
      },
      photoIndex: 0,
      isOpen: false,
      images: {
        titulo: "",
        imagem: "",
        texto: "",
        id: "",
      },
      preloader: false,
      selectedSupervisor: null,
      statusSupervisor: [],
    };
  }

  delete = (cli_codigo) => {
    this.props.deleteProduct(cli_codigo);
  };

  onEdit = (product) => {
    // console.log(product);
    PubSub.publish("edit-product", product);
  };

  DeletarImg = (id, e) => {
    e.preventDefault();
    var numero = String(id);
    const images = this.state.images;
    // var index = images.id.indexOf(numero);
    //  alert('index da galeria '+this.state.photoIndex+'\nesta deletando este id: '+images.id[this.state.photoIndex]+',\nPosicao 0 de emages:  '+images.id[0]+',\nPosicao 1 de emages:  '+images.id[1]+',\nPosicao 2 de emages:  '+images.id[2]);
    var id_delete = images.id[this.state.photoIndex];
    images.id.splice(this.state.photoIndex, 1);
    images.texto.splice(this.state.photoIndex, 1);
    images.titulo.splice(this.state.photoIndex, 1);
    images.imagem.splice(this.state.photoIndex, 1);

    this.setState({ images });
    if (this.state.images.length < 1) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ photoIndex: 0 });
    }

    axios
      .delete("http://52.11.139.178/sicoob/foto/" + id_delete, {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        // console.log(response);

        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleInfo(error.response.data.message);
        console.log(error);
      });
  };

  Galeria = (cli_codigo, e) => {
    e.preventDefault();

    this.setState({ preloader: true });
    axios
      .get("http://52.11.139.178/sicoob/foto/" + cli_codigo, {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        // console.log(response);
        //   console.log('response: ' + JSON.stringify(response));
        this.setState({ isOpen: true });
        this.setState({ preloader: false });
        const images = [];
        const imagem = [];
        const imagem_id = [];
        const titulo = [];
        const texto = [];
        var id = "";
        var botao = "";

        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        for (var i = 0; i < response.data.length; i++) {
          imagem.push("data:image/png;base64," + response.data[i].cf_foto);
          titulo.push(mask_data(response.data[i].cf_data));
          imagem_id.push(response.data[i].cf_id);
          id = response.data[i].cf_id;

          botao = (
            <button
              type="button"
              onClick={(e) => {
                if (
                  window.confirm(
                    "Voce tem certeza que deseja deletar esta imagem ? "
                  )
                )
                  this.DeletarImg(id, e);
              }}
              className="btn btn-outline-primary"
            >
              Deletar Imagem
            </button>
          );

          texto.push(botao);
        }
        //  statusOptions.push({ value: -1, label: 'TODOS GERENTES' });

        images.imagem = imagem;
        images.titulo = titulo;
        images.texto = texto;
        images.id = imagem_id;
        this.setState({ photoIndex: 0 });
        this.setState({ images }); // salvando o novo state
      })
      .catch((error) => {
        this.setState({ preloader: false });
        handleError(error.response.data.message);

        console.log(error);
      });
  };
  handleChange = (selectedSupervisor) => {
    this.setState({ selectedSupervisor }); // atualizando o state de  selectedOption

    if (selectedSupervisor.value === null) {
      handleInfo("Selecione um Gerente");
      return false;
    } else {
      this.props.productBusca(this.state.pesquisar, selectedSupervisor.value);
    }
  };
  componentDidMount() {
    if (localStorage.getItem("supervisor") > 0) {
      axios
        .get(
          "http://52.11.139.178/sicoob/login/funcao/" +
            localStorage.getItem("agencia") +
            "/0",
          {
            auth: {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password"),
            },
          }
        )
        .then((response) => {
          //  console.log(response);
          //  console.log('response: ' + JSON.stringify(response.data));
          const statusSupervisor = [];
          // construindo o corpo das options do select com as variaves vindas da api atraves do axios
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].supervisor < 1) {
              response.data[i].nome = "Gerente: " + response.data[i].nome;
            } else {
              response.data[i].nome = "Supervisor: " + response.data[i].nome;
            }
            statusSupervisor.push({
              value: response.data[i].id,
              label: response.data[i].nome,
            });
          }
          statusSupervisor.push({ value: 0, label: "TODOS GERENTES" });
          this.setState({ statusSupervisor }); // salvando o novo state
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  FiltrarCampos() {
    var input, filter, table, tr, td, i;
    input = document.getElementById("busca");
    filter = input.value.toUpperCase();
    table = document.getElementById("clientes");
    tr = table.getElementsByTagName("tr");
    for (var i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (var j = 0; j < tds.length - 1; j++) {
        var td = tds[j];

        if (td.textContent.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  render() {
    const { products } = this.props;
    const { images } = this.state;
    const { selectedSupervisor, statusSupervisor } = this.state;

    const { photoIndex, isOpen } = this.state;
    return (
      <div className="container">
        {this.state.preloader ? (
          <div id="loading-wrapper">
            <div id="loading-text">
              <picture data-fileentryid="1138056">
                <img src={preloader} width={"110px"} />
              </picture>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-12">
            <form>
              <div className="row">
                {localStorage.getItem("supervisor") > 0 ? (
                  <div className="col-md-4">
                    <label htmlFor="supervisor">Supervisor:</label>
                    <Select
                      id="supervisor"
                      value={selectedSupervisor}
                      onChange={this.handleChange}
                      options={statusSupervisor}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-4 ">
                  <label htmlFor="supervisor">Buscar por:</label>
                  <input
                    className="form-control"
                    id="busca"
                    onChange={(e) => this.FiltrarCampos()}
                    type="text"
                    placeholder="Termo..."
                  />
                </div>
                <div className="col-md-4 "></div>
                {localStorage.getItem("supervisor") < 1 ? (
                  <div className="col-md-4"></div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>{" "}
          <div className="col-12">
            <div className="table-responsive">
              <table
                style={{ marginBottom: "50px" }}
                id="clientes"
                className="table table-hover table-bordered text-center"
              >
                <thead className="thead-dark">
                  <tr>
                    <th>Nome</th>
                    <th>Endereço</th>
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>Contato</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {localStorage.getItem("supervisor") < 1
                    ? // map eh equivalente a um for ou foreach em react
                      products.map((product) => (
                        <tr key={product.cli_codigo}>
                          <td>{product.cli_nome}</td>
                          <td>{product.cli_endereco}</td>
                          <td>{product.cli_bairro}</td>
                          <td>{product.municipio}</td>
                          <td>{product.cli_fone}</td>
                          <td>
                            <button
                              type="button"
                              title="Editar"
                              className="btn btn-primary"
                              style={{ fontSize: "12px", marginTop: "10px" }}
                              onClick={(e) => this.onEdit(product)}
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-danger"
                              title="Deletar"
                              type="button"
                              style={{ fontSize: "12px", marginTop: "10px" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Voce tem certeza que deseja deletar o cliente "' +
                                      product.cli_nome +
                                      '" ?'
                                  )
                                ) {
                                  this.delete(product.cli_codigo);
                                }
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-info"
                              title="Galeria de fotos"
                              type="button"
                              style={{ fontSize: "12px", marginTop: "10px" }}
                              onClick={(e) =>
                                this.Galeria(product.cli_codigo, e)
                              }
                            >
                              <i className="fas fa-camera-retro"></i>
                            </button>
                            &nbsp;&nbsp;
                            {isOpen && (
                              <Lightbox
                                mainSrc={images.imagem[photoIndex]}
                                nextSrc={
                                  images.imagem[
                                    (photoIndex + 1) % images.imagem.length
                                  ]
                                }
                                prevSrc={
                                  images.imagem[
                                    (photoIndex + images.imagem.length - 1) %
                                      images.imagem.length
                                  ]
                                }
                                onCloseRequest={() =>
                                  this.setState({ isOpen: false })
                                }
                                imageTitle={images.titulo[photoIndex]}
                                imageCaption={images.texto[photoIndex]}
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + images.imagem.length - 1) %
                                      images.imagem.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + 1) % images.imagem.length,
                                  })
                                }
                              />
                            )}
                            <Link
                              to={`/visita/${product.cli_codigo}/${product.cli_nome}`}
                            >
                              <button
                                type="button"
                                title="Visitas"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginRight: "6px",
                                }}
                                className="btn btn-success"
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            </Link>
                            <Link
                              to={`/cad_visita/${product.cli_codigo}/${product.cli_nome}`}
                            >
                              <button
                                type="button"
                                title="Cadastrar Visitas"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginRight: "6px",
                                }}
                                className="btn btn-secondary"
                              >
                                <i className="fas fa-car-side"></i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    : // map eh equivalente a um for ou foreach em react
                      products.map((product) => (
                        <tr key={product.cli_codigo}>
                          <td>{product.cli_nome}</td>
                          <td>{product.cli_endereco}</td>
                          <td>{product.cli_bairro}</td>
                          <td>{product.municipio}</td>
                          <td>{product.cli_fone}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              title="Galeria de fotos"
                              type="button"
                              style={{ fontSize: "12px", marginTop: "10px" }}
                              onClick={(e) =>
                                this.Galeria(product.cli_codigo, e)
                              }
                            >
                              <i className="fas fa-camera-retro"></i>
                            </button>
                            &nbsp;&nbsp;
                            {isOpen && (
                              <Lightbox
                                mainSrc={images.imagem[photoIndex]}
                                nextSrc={
                                  images.imagem[
                                    (photoIndex + 1) % images.imagem.length
                                  ]
                                }
                                prevSrc={
                                  images.imagem[
                                    (photoIndex + images.imagem.length - 1) %
                                      images.imagem.length
                                  ]
                                }
                                onCloseRequest={() =>
                                  this.setState({ isOpen: false })
                                }
                                imageTitle={images.titulo[photoIndex]}
                                imageCaption={images.texto[photoIndex]}
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + images.imagem.length - 1) %
                                      images.imagem.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + 1) % images.imagem.length,
                                  })
                                }
                              />
                            )}
                            <Link to={`/visita/${product.cli_codigo}`}>
                              <button
                                type="button"
                                title="Vizualizar Visitas"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "10px",
                                  marginRight: "6px",
                                }}
                                className="btn btn-success"
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class ProductBox extends Component {
  // declando variaveis de states
  state = {
    products: [],
    lng: "",
    lat: "",
    message: {
      text: "",
      alert: "",
    },
  };

  componentDidMount() {
    //utilizando a dependencia axios para buscar os dados do Gerente atraves de ajax
    if (localStorage.getItem("supervisor") < 1) {
      axios
        .get(
          "http://52.11.139.178/sicoob/cliente/" +
            localStorage.getItem("agencia") +
            "/" +
            localStorage.getItem("id") +
            "/",
          {
            auth: {
              username: localStorage.getItem("username"),
              password: localStorage.getItem("password"),
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.setState({ products: res.data });
          }
        })
        .catch((error) => {
          handleError(error.response.data.message);
          console.log(error.response);
        });
    }
  }

  buscar = (pesquisar, supervisor) => {
    axios
      .get(
        "http://52.11.139.178/sicoob/cliente/" +
          localStorage.getItem("agencia") +
          "/" +
          supervisor +
          "/" +
          pesquisar.busca,
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((res) => {
        if (res.data != "") {
          this.setState({ products: res.data });
        }
      })
      .catch((error) => {
        const products = [];
        this.setState({ products: products });
        handleError(error.response.data.message);
        console.log(error.response);
      });
  };

  save = (product) => {
    //   console.log('municipio original '+product.municipio);
    var municipio = String(product.municipio);
    municipio = municipio.replace(" / ", ",");
    //    console.log('municipio final '+municipio);
    var APIKey = "AIzaSyBWQHc9r61osjF-vtiP8IYcCB_8j5Cp8gw"; // Chaves de API ky_maps_javascript
    axios({
      method: "get",
      url:
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        product.cli_endereco +
        "," +
        product.cli_numero +
        "," +
        municipio +
        "&key=" +
        APIKey,

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        var latitude = response.data.results[0].geometry.location.lat;
        var longitude = response.data.results[0].geometry.location.lng;
        localStorage.setItem("cli_latitude", latitude);
        localStorage.setItem("cli_longitude", longitude);
      })
      .catch((error) => {
        handleError(error.response.data.message);
        console.log(error.response);
      });
    let data = {
      cli_codigo: parseInt(product.cli_codigo),
      cli_idgerente: parseInt(localStorage.getItem("id")),
      cli_nome: product.cli_nome,
      cli_endereco: product.cli_endereco,
      cli_numero: product.cli_numero,
      cli_bairro: product.cli_bairro,
      cli_cidade: product.cli_cidade,
      cli_fone: product.cli_fone,
      cli_contato: product.cli_contato,
      cli_celular: product.cli_celular,
      cli_latitude: String(localStorage.getItem("cli_latitude")),
      cli_longitude: String(localStorage.getItem("cli_longitude")),
      cli_agencia: parseInt(localStorage.getItem("agencia")),
    };

    // console.log('data eh: ' + JSON.stringify(data));
    //  console.log('status eh: ' + JSON.stringify(product.cli_fone));
    if (product.cli_codigo === 0) {
      // CREATE NEW PRODUCT

      axios({
        method: "post",
        url: "http://52.11.139.178/sicoob/cliente",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
        .then((response) => {
          let data1 = {
            cli_codigo: parseInt(product.cli_codigo),
            municipio: product.municipio,
            cli_idgerente: parseInt(localStorage.getItem("id")),
            cli_nome: product.cli_nome,
            cli_endereco: product.cli_endereco,
            cli_numero: product.cli_numero,
            cli_bairro: product.cli_bairro,
            cli_cidade: product.cli_cidade,
            cli_fone: product.cli_fone,
            cli_contato: product.cli_contato,
            cli_celular: product.cli_celular,
            cli_latitude: String(localStorage.getItem("cli_latitude")),
            cli_longitude: String(localStorage.getItem("cli_longitude")),
            cli_agencia: parseInt(localStorage.getItem("agencia")),
          };

          //   console.log(JSON.stringify(data1));

          this.setState((state) => ({
            products: state.products.concat([data1]), // adicionando esse novo registro ao state de products, de forma de verdadeiramente aparacer na tabela de Gerentes
          }));

          let { products } = this.state;
          //   console.log("este eh o estado: "+JSON.stringify(products));
          //  products.push(newProduct);
          this.setState({ products });
          handleSuccess(response.data.message);
          axios
            .get(
              "http://52.11.139.178/sicoob/cliente/" +
                localStorage.getItem("agencia") +
                "/" +
                localStorage.getItem("id") +
                "/",
              {
                auth: {
                  username: localStorage.getItem("username"),
                  password: localStorage.getItem("password"),
                },
              }
            )
            .then((res) => {
              if (res.data != "") {
                console.log("res do axios " + JSON.stringify(res.data));
                // this.setState({products : res.data});// atribuindo o resultado da consulta para o array de states products,
                //eh importante que products tenha os indices com o nome das colunas da consulta.
                //console.log("res do axios "+res.data.length);
                //  console.log("res do axios "+JSON.stringify(res.data));

                this.setState({ products: res.data });
                //  console.log("res do axios "+JSON.stringify(res.data[0].prod_preco));
              }
            })
            .catch((error) => {
              handleError(error.response.data.message);
              console.log(error.response);
            });

          // this.setState({ products, message: { text: 'Novo Gerente adicionado com sucesso!', alert: 'success' } });
          // this.timerMessage(3000);
        })
        .catch((error) => {
          handleError(error.response.data.message);
          console.log(error.response);
          return false;
        });
    } else {
      // EDIT PRODUCT
      axios({
        method: "put", //you can set what request you want to be
        url: "http://52.11.139.178/sicoob/cliente",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
        .then((response) => {
          let { products } = this.state;
          let position = products.findIndex(
            (product) => product.cli_codigo !== data.cli_codigo
          ); //alterando os dados da tabela
          this.setState({ products });
          handleSuccess(response.data.message);
        })
        .catch((error) => {
          handleError(error.response.data.message);
          return false;
        });
    }
  };

  delete = (cli_codigo) => {
    axios({
      method: "DELETE", //you can set what request you want to be
      url: "http://52.11.139.178/sicoob/cliente/" + cli_codigo,
      data: cli_codigo,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((rows) => {
        const products = this.state.products.filter(
          (product) => product.cli_codigo !== cli_codigo
        );
        //  this.setState({ products,  message: { text: 'Gerente deletado com sucesso.', alert: 'danger' } });
        //  this.timerMessage(3000);
        this.setState({ products });
        handleInfo(rows.data.message);
      })
      .catch((error) => {
        handleError(error.response.data.message);
        console.log(error.response);
      });
  };
  // metodo responsavel pelas mensagens de sucesso e erro
  timerMessage = (duration) => {
    setTimeout(() => {
      this.setState({ message: { text: "", alert: "" } });
    }, duration);
  };

  render() {
    return (
      <div className="container">
        {
          this.state.message.text !== "" ? (
            <alert color={this.state.message.alert} className="text-center">
              {" "}
              {this.state.message.text}{" "}
            </alert>
          ) : (
            ""
          )

          // abaixo eh visto o instanciamento dos componentes FormProduct e ListProduct
        }

        <div className="row">
          {localStorage.getItem("supervisor") < 1 ? (
            <div className="col-md-12 ">
              <div class="shadow-lg  mb-5 bg-white rounded card">
                <div class="card-header">
                  <h3 className="font-weight-bold text-center">
                    {" "}
                    Cadastro de Clientes{" "}
                  </h3>
                </div>
                <div class="card-body">
                  <FormProduct productCreate={this.save} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-md-12 ">
            <div class="shadow-lg  mb-5 bg-white rounded card">
              <div class="card-header">
                <h3 className="font-weight-bold text-center">
                  {" "}
                  Lista de Clientes{" "}
                </h3>
              </div>
              <div class="card-body">
                <ListProduct
                  products={this.state.products}
                  productBusca={this.buscar}
                  deleteProduct={this.delete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";

import {
  api,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../Plugins/Config/Config";
class Cadastrar_solicitacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grupo: {
        sg_id: 0,
        sg_descricao: "",
        sg_status: "",
        sg_descricao_alterar: "",
        ssg_descricao: "",
        ssg_descricao_alterar: "",
      },
      grupos: [],
      subgrupos: [],
      objetivos: [],
      modal: [],
    };
  }

  // função para escrever nos inputs e ja associando ao state
  setValues = (e, field) => {
    const { grupo } = this.state;
    grupo[field] = e.target.value;
    this.setState({ grupo });
  };

  //função que recebe os dados do accodian para preencher o state modal que tem o intuito de preencher os modal
  ModalDados = (grupo_soli, tipo) => {
    this.setState({
      modal: {
        sg_id: grupo_soli.sg_id,
        sg_descricao: grupo_soli.sg_descricao,
        sg_status: grupo_soli.sg_status,
        titulo: tipo,
        ssg_descricao: grupo_soli.ssg_descricao,
        ssg_id: grupo_soli.ssg_id,
        ssg_grupo: grupo_soli.ssg_grupo,
        ssg_status: grupo_soli.ssg_status,
      },
    });
  };

  // função para cadastrar grupo
  save = (tipo) => {
    const { grupo } = this.state;
    if (tipo == "sub") {
      document.getElementById("submodal_fechar").click();

      var url = "sicoob/solsubgrupo";
      const { modal } = this.state;
      if (grupo.ssg_descricao !== "") {
        var data = {
          ssg_grupo: modal.sg_id,
          ssg_descricao: grupo.ssg_descricao,
          ssg_documento: "",
          ssg_agencia: parseInt(localStorage.getItem("agencia")),
        };
      }
    } else {
      var url = "sicoob/solgrupo";
      if (grupo.sg_descricao !== "") {
        var data = {
          sg_descricao: grupo.sg_descricao,
          sg_agencia: parseInt(localStorage.getItem("agencia")),
        };
      }
    }

    if (grupo.sg_descricao == "" && grupo.ssg_descricao == "") {
      handleError("Preencha os campos");
      return false;
    }

    this.setState({
      modal: {
        sg_id: "",
        sg_descricao: "",
        sg_status: "",
        titulo: "",
        ssg_descricao_alterar: "",
        ssg_descricao: "",
        ssg_id: "",
        ssg_grupo: "",
      },
    });
    this.setState({
      grupo: {
        sg_descricao: "",
        sg_status: "",
        sg_id: 0,
        sg_descricao_alterar: "",
        ssg_descricao: "",
        ssg_descricao_alterar: "",
      },
    });

    api({
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);
        this.atualizar_acordian();
      })
      .catch((error) => {
        console.log(error);
        handleError(error.response.data.message);
        return false;
      });
  };

  //função para atualizar o accordian caso haja alguma mudança no state grupos
  atualizar_acordian() {
    api
      .get("sicoob/solgrupo/" + localStorage.getItem("agencia") + "/X", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != "") {
          this.setState({ grupos: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get("sicoob/solsubgrupo/" + localStorage.getItem("agencia") + "/X", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        //  console.log('response: ' + JSON.stringify(response.data));
        if (response.data != "") {
          this.setState({ subgrupos: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // função referente aos botões ativar e desativar em modal grupo e subgrupo
  status = (id, status, tipo) => {
    const { modal } = this.state;
    if (tipo == "sub") {
      var url = "sicoob/solsubgrupo";
      var data = {
        ssg_id: id,
        ssg_status: status,
        ssg_descricao: modal.ssg_descricao,
        ssg_grupo: modal.ssg_grupo,
        ssg_agencia: parseInt(localStorage.getItem("agencia")),
      };
      this.setState({ modal: { ssg_status: status } });
      document.getElementById("subeditmodal_fechar").click();
    } else {
      var url = "sicoob/solgrupo";
      var data = {
        sg_id: id,
        sg_descricao: modal.sg_descricao,
        sg_status: status,
        sg_agencia: parseInt(localStorage.getItem("agencia")),
      };
      this.setState({ modal: { sg_status: status } });
      document.getElementById("fechargrupo").click();
    }

    api({
      method: "put",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);
        this.atualizar_acordian();
      })
      .catch((error) => {
        console.log(error);
        handleError(error.response.data.message);
        return false;
      });
  };
  // função para alterar o nome do grupo
  alterarGrupo = (id, tipo) => {
    const { grupo } = this.state;
    const { modal } = this.state;
    if (tipo == "sub") {
      if (grupo.ssg_descricao_alterar !== "") {
        document.getElementById("subeditmodal_fechar").click();
        var url = "sicoob/solsubgrupo";
        var data = {
          ssg_id: id,
          ssg_descricao: grupo.ssg_descricao_alterar,
          ssg_grupo: modal.ssg_grupo,
          ssg_status: modal.ssg_status,
          ssg_agencia: parseInt(localStorage.getItem("agencia")),
        };
      } else {
        handleError("Preencha o novo nome do SubGrupo");
        return false;
      }
    } else {
      if (grupo.sg_descricao_alterar !== "") {
        document.getElementById("fechargrupo").click();
        var url = "sicoob/solgrupo";

        var data = {
          sg_id: id,
          sg_descricao: grupo.sg_descricao_alterar,
          sg_status: modal.sg_status,
          sg_agencia: parseInt(localStorage.getItem("agencia")),
        };
      } else {
        handleError("Preencha o novo nome do Grupo");
        return false;
      }
    }
    this.setState({
      modal: {
        sg_id: "",
        sg_descricao: "",
        sg_status: "",
        titulo: "",
        ssg_descricao_alterar: "",
        ssg_descricao: "",
        ssg_id: "",
        ssg_grupo: "",
      },
    });
    this.setState({
      grupo: {
        sg_descricao: "",
        sg_status: "",
        sg_id: 0,
        sg_descricao_alterar: "",
        ssg_descricao: "",
        ssg_descricao_alterar: "",
      },
    });

    api({
      method: "put",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        handleSuccess(response.data.message);
        this.atualizar_acordian();
      })
      .catch((error) => {
        console.log(error);
        handleError(error.response.data.message);
        return false;
      });
  };

  // função para deletar o grupo
  DeletarGrupo = (id, tipo) => {
    if (tipo == "sub") {
      var url = "sicoob/solsubgrupo/";
    } else {
      var url = "sicoob/solgrupo/";
    }

    api({
      method: "DELETE", //you can set what request you want to be
      url: url + id,
      data: id,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((rows) => {
        //  const grupos = this.state.grupos.filter(grupo_soli => grupo_soli.sg_id !== sg_id);
        //  this.setState({ grupos });
        this.atualizar_acordian();
        handleInfo(rows.data.message);
      })
      .catch((error) => {
        handleError(error.response.data.message);
        console.log(error.response);
      });
  };

  // função que executa sempre que a pagina é carregada
  componentDidMount = () => {
    this.atualizar_acordian();
  };

  //  função para formar o primeiro acordian do lado esquerdo
  metadeInferior = (value) => {
    const { grupos } = this.state;
    var metade = parseInt(grupos.length / 2);
    // return value >= 10;
    var index = grupos.indexOf(value);
    if (index > -1) {
      if (index < metade) {
        return value;
      }
    }
  };
  //  função para formar o segundo acordian do lado direito
  metadeSuperior = (value) => {
    const { grupos } = this.state;
    var metade = parseInt(grupos.length / 2);
    // return value >= 10;
    var index = grupos.indexOf(value);
    if (index > -1) {
      if (index >= metade && index <= grupos.length) {
        return value;
      }
    }
  };
  render() {
    const { subgrupos } = this.state;
    const { grupos } = this.state;
    const { modal } = this.state;
    return (
      <>
        <div className="container">
          {
            //modal referente ao cadastro de novos subgrupos de solicitação
          }
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-dark"
                    id="exampleModalLongTitle"
                  >
                    <b>Cadastrar SubGrupo</b>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form>
                  <div className="modal-body">
                    <div className="form-row">
                      <legend
                        style={{ fontSize: "14px", marginBottom: "20px" }}
                      >
                        Cadastre o nome do SubGrupo da solicitação{" "}
                        <b>{modal.sg_descricao}</b>
                      </legend>
                      <div className="form-group col-md-12">
                        <label htmlFor="ssg_descricao">
                          <b>Nome de SubGrupo:</b>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="ssg_descricao"
                          onChange={(e) => this.setValues(e, "ssg_descricao")}
                          value={this.state.grupo.ssg_descricao}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={(e) => this.save("sub")}
                      className="btn btn-primary"
                    >
                      Salvar
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="submodal_fechar"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {
            //modal referente ao alterar de novos subgrupos de solicitação
          }
          <div
            className="modal fade"
            id="editarSubGrupo"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editarSubGrupoTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-dark"
                    id="exampleModalLongTitle"
                  >
                    <b>Editar SubGrupo</b>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form>
                  <div className="modal-body">
                    <div className="form-row">
                      <legend
                        style={{ fontSize: "14px", marginBottom: "20px" }}
                      >
                        Altere o nome do SubGrupo selecionado para novo nome
                        desejado
                      </legend>
                      <div className="form-group col-md-12">
                        <label htmlFor="grupo">
                          <b>{modal.titulo} Selecionado:</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="grupo"
                          readOnly
                          value={modal.ssg_descricao}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="ssg_descricao_alterar">
                          <b>Novo Nome:</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setValues(e, "ssg_descricao_alterar")
                          }
                          value={this.state.grupo.ssg_descricao_alterar}
                          id="ssg_descricao_alterar"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {modal.ssg_status == "A" ? (
                      <button
                        type="button"
                        onClick={(e) => this.status(modal.ssg_id, "I", "sub")}
                        className="btn btn-danger"
                      >
                        Desativar
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={(e) => this.status(modal.ssg_id, "A", "sub")}
                        className="btn btn-success"
                      >
                        Ativar
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={(e) => this.alterarGrupo(modal.ssg_id, "sub")}
                      className="btn btn-primary"
                    >
                      Salvar
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="subeditmodal_fechar"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {
            //modal referente ao alterar grupo
          }
          <div
            className="modal fade"
            id="editarGrupo"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editarGrupoTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-dark"
                    id="exampleModalLongTitle"
                  >
                    {"Alterar " + modal.titulo}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <legend style={{ fontSize: "14px", marginBottom: "20px" }}>
                      Altere o nome do grupo selecionado para novo nome desejado
                    </legend>
                    <div className="form-group col-md-12">
                      <label htmlFor="grupo">
                        <b>{modal.titulo} Selecionado:</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="grupo"
                        readOnly
                        value={modal.sg_descricao}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="sg_descricao_alterar">
                        <b>Novo Nome:</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setValues(e, "sg_descricao_alterar")
                        }
                        value={this.state.grupo.sg_descricao_alterar}
                        id="sg_descricao_alterar"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {modal.sg_status == "A" ? (
                    <button
                      type="button"
                      onClick={(e) => this.status(modal.sg_id, "I", "grupo")}
                      className="btn btn-danger"
                    >
                      Desativar
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => this.status(modal.sg_id, "A", "grupo")}
                      className="btn btn-success"
                    >
                      Ativar
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={(e) => this.alterarGrupo(modal.sg_id, "grupo")}
                    className="btn btn-primary"
                  >
                    Alterar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    id="fechargrupo"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="shadow-lg  mb-5 bg-white rounded card">
            <div class="card-header">
              <h3 className="font-weight-bold text-center">
                Cadastro Tipos de Solicitação
              </h3>
            </div>
            <div class="card-body">
              <form style={{ marginTop: "30px", marginBottom: "30px" }}>
                <legend style={{ fontSize: "14px", marginBottom: "20px" }}>
                  Cadastre os tipos de solicitações relacionando a sua cor
                  identificadora, Pelo botão de{" "}
                  <i
                    style={{ color: "#007bff" }}
                    className="fas fa-plus-circle"
                  ></i>{" "}
                  adicione Subgrupos de solicitações referente ao tipo desejado
                  de solicitação :
                </legend>

                <div className="form-row">
                  <div className="col-md-4 ">
                    <label htmlFor="sg_descricao">Nome:</label>
                    <input
                      id="sg_descricao"
                      className="form-control"
                      required="required"
                      type="text"
                      value={this.state.grupo.sg_descricao}
                      placeholder="Nome da Grupo..."
                      onChange={(e) => this.setValues(e, "sg_descricao")}
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      type="button"
                      title="Cadastrar"
                      onClick={(e) => this.save("grupo")}
                      className="btn btn-primary"
                      style={{ marginTop: "16%" }}
                    >
                      Cadastrar <i className="fas fa-save"></i>
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-6">
                  <div id="accordion" className="myaccordion">
                    {grupos.filter(this.metadeInferior).map((grupo_soli) => (
                      <div
                        key={grupo_soli.sg_id}
                        className="card shadow-lg bg-white rounded"
                      >
                        <div className="card-header" id="One">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed "
                              style={{ color: "#000000" }}
                              data-toggle="collapse"
                              data-target={"#collapse" + grupo_soli.sg_id}
                              aria-expanded="false"
                              aria-controls={"collapse" + grupo_soli.sg_id}
                            >
                              {grupo_soli.sg_descricao}
                            </button>
                            {grupo_soli.sg_status == "A" ? (
                              <span
                                style={{ color: "#007bff" }}
                                onClick={(e) =>
                                  this.ModalDados(grupo_soli, "Grupo")
                                }
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                className="fa-stack fa-sm"
                              >
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                              </span>
                            ) : (
                              <span
                                style={{ color: "#6c757d" }}
                                onClick={(e) =>
                                  this.ModalDados(grupo_soli, "Grupo")
                                }
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                className="fa-stack fa-sm"
                              >
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                              </span>
                            )}
                          </h2>
                        </div>
                        <div
                          id={"collapse" + grupo_soli.sg_id}
                          className="collapse"
                          aria-labelledby="One"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <ul>
                              <li
                                className="row"
                                style={{
                                  backgroundColor: "#dcdcdc",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="col-md-9 col-9  ">
                                  <b>{grupo_soli.sg_descricao}</b>
                                </div>
                                <div
                                  className="col-md-3 col-2  "
                                  style={{ maxWidth: "4.66667%" }}
                                >
                                  {grupo_soli.sg_status == "A" ? (
                                    <button
                                      type="button"
                                      title="Editar"
                                      className="btn btn_style btn-primary"
                                      onClick={(e) =>
                                        this.ModalDados(grupo_soli, "Grupo")
                                      }
                                      data-toggle="modal"
                                      data-target="#editarGrupo"
                                      style={{
                                        color: "white",
                                        fontSize: "12px",
                                        width: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      title="Editar"
                                      className="btn btn_style btn-secondary"
                                      onClick={(e) =>
                                        this.ModalDados(grupo_soli, "Grupo")
                                      }
                                      data-toggle="modal"
                                      data-target="#editarGrupo"
                                      style={{
                                        color: "white",
                                        fontSize: "12px",
                                        width: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  )}
                                </div>
                                <div
                                  className="col-md-3 col-1  "
                                  style={{ maxWidth: "6.66667%" }}
                                >
                                  <button
                                    type="button"
                                    title="Deletar"
                                    onClick={(e) => {
                                      if (
                                        window.confirm(
                                          "Voce tem certeza que deseja deletar o grupo " +
                                            grupo_soli.sg_descricao +
                                            " ?"
                                        )
                                      )
                                        this.DeletarGrupo(
                                          grupo_soli.sg_id,
                                          "grupo"
                                        );
                                    }}
                                    className="btn btn_style btn-danger"
                                    style={{
                                      color: "white",
                                      fontSize: "12px",
                                      width: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </button>
                                </div>
                              </li>
                              {subgrupos.map(
                                function (cell, i) {
                                  const { grupos } = this.state;
                                  if (cell.ssg_grupo == grupo_soli.sg_id) {
                                    return (
                                      <li className="row">
                                        <div className="col-md-9 col-9  ">
                                          {cell.ssg_descricao}{" "}
                                        </div>
                                        <div
                                          className="col-md-3 col-2  "
                                          style={{ maxWidth: "4.66667%" }}
                                        >
                                          {cell.ssg_status == "A" &&
                                          grupo_soli.sg_status == "A" ? (
                                            <button
                                              type="button"
                                              title="Editar"
                                              className="btn btn_style  btn-primary"
                                              onClick={(e) =>
                                                this.ModalDados(cell)
                                              }
                                              data-toggle="modal"
                                              data-target="#editarSubGrupo"
                                              style={{
                                                color: "white",
                                                fontSize: "12px",
                                                width: "20px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              title="Editar"
                                              className="btn btn_style  btn-secondary"
                                              onClick={(e) =>
                                                this.ModalDados(cell)
                                              }
                                              data-toggle="modal"
                                              data-target="#editarSubGrupo"
                                              style={{
                                                color: "white",
                                                fontSize: "12px",
                                                width: "20px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          )}
                                        </div>
                                        <div
                                          className="col-md-3 col-1  "
                                          style={{ maxWidth: "6.66667%" }}
                                        >
                                          <button
                                            type="button"
                                            title="Deletar"
                                            className="btn btn_style btn-danger"
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Voce tem certeza que deseja deletar o grupo " +
                                                    cell.ssg_descricao +
                                                    " ?"
                                                )
                                              )
                                                this.DeletarGrupo(
                                                  cell.ssg_id,
                                                  "sub"
                                                );
                                            }}
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                              width: "20px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <i className="far fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </li>
                                    );
                                  }
                                }.bind(this)
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-6">
                  <div id="accordion1" className="myaccordion">
                    {grupos.filter(this.metadeSuperior).map((grupo_soli) => (
                      <div
                        key={grupo_soli.sg_id}
                        className="card shadow-lg bg-white rounded"
                      >
                        <div className="card-header" id="headingOne">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              style={{ color: "#000000" }}
                              data-toggle="collapse"
                              data-target={"#collapse" + grupo_soli.sg_id}
                              aria-expanded="false"
                              aria-controls={"collapse" + grupo_soli.sg_id}
                            >
                              {grupo_soli.sg_descricao}
                            </button>
                            {grupo_soli.sg_status == "A" ? (
                              <span
                                style={{ color: "#007bff" }}
                                onClick={(e) =>
                                  this.ModalDados(grupo_soli, "Grupo")
                                }
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                className="fa-stack fa-sm"
                              >
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                              </span>
                            ) : (
                              <span
                                style={{ color: "#6c757d" }}
                                onClick={(e) =>
                                  this.ModalDados(grupo_soli, "Grupo")
                                }
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                className="fa-stack fa-sm"
                              >
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
                              </span>
                            )}
                          </h2>
                        </div>
                        <div
                          id={"collapse" + grupo_soli.sg_id}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordion1"
                        >
                          <div className="card-body">
                            <ul>
                              <li
                                className="row"
                                style={{
                                  backgroundColor: "#dcdcdc",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="col-md-9 col-9  ">
                                  <b>{grupo_soli.sg_descricao}</b>
                                </div>
                                <div
                                  className="col-md-3 col-2  "
                                  style={{ maxWidth: "4.66667%" }}
                                >
                                  {grupo_soli.sg_status == "A" ? (
                                    <button
                                      type="button"
                                      title="Editar"
                                      className="btn btn_style btn-primary"
                                      onClick={(e) =>
                                        this.ModalDados(grupo_soli, "Grupo")
                                      }
                                      data-toggle="modal"
                                      data-target="#editarGrupo"
                                      style={{
                                        color: "white",
                                        fontSize: "12px",
                                        width: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      title="Editar"
                                      className="btn btn_style btn-secondary"
                                      onClick={(e) =>
                                        this.ModalDados(grupo_soli, "Grupo")
                                      }
                                      data-toggle="modal"
                                      data-target="#editarGrupo"
                                      style={{
                                        color: "white",
                                        fontSize: "12px",
                                        width: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  )}
                                </div>
                                <div
                                  className="col-md-3 col-1  "
                                  style={{ maxWidth: "6.66667%" }}
                                >
                                  <button
                                    type="button"
                                    title="Deletar"
                                    onClick={(e) => {
                                      if (
                                        window.confirm(
                                          "Voce tem certeza que deseja deletar o grupo " +
                                            grupo_soli.sg_descricao +
                                            " ?"
                                        )
                                      )
                                        this.DeletarGrupo(
                                          grupo_soli.sg_id,
                                          "grupo"
                                        );
                                    }}
                                    className="btn btn_style btn-danger"
                                    style={{
                                      color: "white",
                                      fontSize: "12px",
                                      width: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <i className="far fa-trash-alt"></i>
                                  </button>
                                </div>
                              </li>
                              {subgrupos.map(
                                function (cell, i) {
                                  if (cell.ssg_grupo == grupo_soli.sg_id) {
                                    return (
                                      <li className="row">
                                        <div className="col-md-9 col-9  ">
                                          {cell.ssg_descricao}{" "}
                                        </div>
                                        <div
                                          className="col-md-3 col-2  "
                                          style={{ maxWidth: "4.66667%" }}
                                        >
                                          {cell.ssg_status == "A" &&
                                          grupo_soli.sg_status == "A" ? (
                                            <button
                                              type="button"
                                              title="Editar"
                                              className="btn btn_style  btn-primary"
                                              onClick={(e) =>
                                                this.ModalDados(cell)
                                              }
                                              data-toggle="modal"
                                              data-target="#editarSubGrupo"
                                              style={{
                                                color: "white",
                                                fontSize: "12px",
                                                width: "20px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              title="Editar"
                                              className="btn btn_style  btn-secondary"
                                              onClick={(e) =>
                                                this.ModalDados(cell)
                                              }
                                              data-toggle="modal"
                                              data-target="#editarSubGrupo"
                                              style={{
                                                color: "white",
                                                fontSize: "12px",
                                                width: "20px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          )}
                                        </div>
                                        <div
                                          className="col-md-3 col-1  "
                                          style={{ maxWidth: "6.66667%" }}
                                        >
                                          <button
                                            type="button"
                                            title="Deletar"
                                            className="btn btn_style btn-danger"
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Voce tem certeza que deseja deletar o grupo " +
                                                    cell.ssg_descricao +
                                                    " ?"
                                                )
                                              )
                                                this.DeletarGrupo(
                                                  cell.ssg_id,
                                                  "sub"
                                                );
                                            }}
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                              width: "20px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <i className="far fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </li>
                                    );
                                  }
                                }.bind(this)
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Cadastrar_solicitacao;

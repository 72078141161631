import React, { Component } from 'react';
import {api,handleError,handleSuccess} from '../Plugins/Config/Config';
import {mask_hora} from '../Plugins/Mascara/Mascara';
import Select from 'react-select';
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
class Transferir extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedObjetivo: '',
            statusObjetivo: [],
            selectedResultado: '',
            statusResultado: [],
            cliente_nome: '',
            model: {
                data: '',
                horainicio: '',
                horafinal: '',
                objetivo: '',
                resultado: '',
                cv_resultadohistorico: '',
                cliente_id:'',
               

            },
        }


    }
   
    validanull = (e, field) => {
        const { model } = this.state;
        model[field] = e.target.value;
        if (model.horainicio.substring(0, 2) >= 0 && (model.horainicio.substring(0, 2) < 24) && (model.horainicio.substring(3, 5) >= 0) && (model.horainicio.substring(3, 5) < 60)) {
            model.horainicio = model.horainicio;
        } else {
            model.horainicio = '';
            this.setState({ model });
        }
        if (model.horafinal.substring(0, 2) >= 0 && (model.horafinal.substring(0, 2) < 24) && (model.horafinal.substring(3, 5) >= 0) && (model.horafinal.substring(3, 5) < 60)) {

            model.horafinal = model.horafinal;

        } else {
            model.horafinal = '';
            this.setState({ model });
        }


    }


    setValues = (e, field) => {
        const { model } = this.state;
        model[field] = e.target.value;
        model.horainicio = mask_hora(model.horainicio);
        model.horafinal = mask_hora(model.horafinal);
        this.setState({ model });

    }
    // funcao para alterar o valor do campo select
    handleChange = (selectedObjetivo) => {
        const { model } = this.state;
        model.objetivo = selectedObjetivo.value;
        this.setState({ model });
        this.setState({ selectedObjetivo }); // atualizando o state de  selectedObjetivo   
    }

    atualizar_get() {
        api.get('sicoob/objetivo', {
            auth: {
                username: localStorage.getItem("username"),
                password: localStorage.getItem("password")
            }
        })
            .then((response) => {             
             
                if (response.data != '') {
                    const statusObjetivo = []
                    // construindo o corpo das options do select com as variaves vindas da api atraves do axios
                    for (var i = 0; i < response.data.length; i++) {

                        statusObjetivo.push({ value: response.data[i].id, label: response.data[i].descricao });
                    }
                    //  statusObjetivo.push({ value: -1, label: 'TODOS GERENTES' });
                    this.setState({ statusObjetivo })// salvando o novo state
                }
            })
            .catch((error) => {
                console.log(error)
            })

            api.get('sicoob/resultado', {
            auth: {
                username: localStorage.getItem("username"),
                password: localStorage.getItem("password")
            }
        })
            .then((response) => {
              
                if (response.data != '') {
                    const statusResultado = []
                    // construindo o corpo das options do select com as variaves vindas da api atraves do axios
                    for (var i = 0; i < response.data.length; i++) {

                        statusResultado.push({ value: response.data[i].id, label: response.data[i].descricao });
                    }
                    //  statusObjetivo.push({ value: -1, label: 'TODOS GERENTES' });
                    this.setState({ statusResultado })// salvando o novo state
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }
    componentDidMount = () => {
        this.atualizar_get();
        const { model } = this.state;      
        const { cli_codigo } = this.props.match.params;
        var { cli_nome } = this.props.match.params;
        model.cliente_id = cli_codigo;
      
        this.setState({ model });
        this.setState({ cliente_nome : cli_nome });
        
    }
    handleChangeResultado = (selectedResultado) => {
        const { model } = this.state;
        model.resultado = selectedResultado.value;
        this.setState({ model });
        this.setState({ selectedResultado });
    }
 
        save = (model, e) => {
     
       
        if (model.data !== '' && model.horainicio !== '' && model.horainicio !== '' && model.horainicio !== '' && model.horainicio !== '') {

            var data = {
                cv_cliente: parseInt(model.cliente_id),
                cv_gerente:  parseInt(localStorage.getItem('id')),
                cv_data: model.data,
                cv_horainicio: model.horainicio+':00',
                cv_horafim: model.horafinal+':00',
                cv_objetivo: parseInt(model.objetivo),
                cv_resultado: parseInt(model.resultado),
                cv_resultadohistorico: model.cv_resultadohistorico,

            };

        //    console.log(JSON.stringify(data));

this.setState({ modal: { data: '', horainicio: '', cv_resultadohistorico: '', horafinal: '', resultado: '', objetivo: ''} })


api({
    method: 'post',
    url: 'sicoob/cv',
    data: data,
    headers: {
        'Content-Type': 'application/json'
    },
    auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password")
    }
})
    .then(response => {
        handleSuccess(response.data.message);
        this.props.history.push("/cliente");  

    })
    .catch((error) => {
        console.log(error)
        handleError(error.data.message);
        return false;
    });
            }else{
                handleError('Preencha todos campos');
                return false;
            }

    }
render() {
    const { selectedObjetivo, statusObjetivo } = this.state;
    const { selectedResultado, statusResultado } = this.state;
    const { cliente_nome } = this.state;
    


    return (
        <div className="container" >
            <h2 className="font-weight-bold text-center" style={{ marginTop: '30px' }}> Cadastrar Visitas </h2>
            <form style={{ marginTop: '30px', marginBottom: '30px' }}>
    <legend style={{ fontSize: '16px', marginBottom: '20px' }}>Cadastro de visita referente ao cliente:<b>{cliente_nome}</b></legend>
                <div className="form-row">

                    <div className="col-md-4">
                        <label htmlFor="gerente">Objetivos:</label>
                        <Select id='gerente'
                            required
                            value={selectedObjetivo}
                            onChange={this.handleChange}
                            options={statusObjetivo} />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="NovoGerente">Resultados:</label>
                        <Select id='NovoGerente'
                            required
                            value={selectedResultado}
                            onChange={this.handleChangeResultado}
                            options={statusResultado} />
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="data">Data Inicial:</label>
                        <input className='form-control' id="data" required="required" type="date" value={this.state.model.data} placeholder="__/__/____"
                            onChange={e => this.setValues(e, 'data')} />
                    </div>
                    <div className="col-md-1">
                        <label htmlFor="horainicio">Hora Inicial:</label>
                        <input className='form-control' id="horainicio" onBlur={e => this.validanull(e, 'horainicio')} required="required" type="text" value={this.state.model.horainicio} placeholder="__:__"
                            onChange={e => this.setValues(e, 'horainicio')} />
                    </div>
                    <div className="col-md-1">
                        <label htmlFor="horafinal">Hora Final:</label>
                        <input className='form-control' id="horafinal" required="required" type="text" onBlur={e => this.validanull(e, 'horafinal')} value={this.state.model.horafinal} placeholder="__:__"
                            onChange={e => this.setValues(e, 'horafinal')} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="cv_resultadohistorico">Histórico de visita:</label>
                        <textarea className="form-control" value={this.state.model.cv_resultadohistorico}
                            onChange={e => this.setValues(e, 'cv_resultadohistorico')} id="cv_resultadohistorico" rows="6"></textarea>
                    </div>
                </div>

                <div className="text-center" style={{ marginTop: '10px' }}>
                    <button type='button' className='btn btn-primary' onClick={e => this.save(this.state.model,e)}> Cadastrar&nbsp;<i className="fas fa-save"></i> </button>
                </div>



            </form>

        </div>
    );
}
}
export default Transferir;
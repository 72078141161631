import React, { useState, useEffect } from "react";
import { api, handleError } from "../Plugins/Config/Config";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mask_data } from "../Plugins/Mascara/Mascara";

export default function Evento() {
  const [selectedOption, setSelectedOption] = useState([null]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [dataini, setDataini] = useState([""]);
  const [datafim, setDatafim] = useState([""]);
  const [clientes, setClientes] = useState([]);

  function HandleChange(selectedOption) {
    setSelectedOption(selectedOption);
  }

  useEffect(() => {
    if (localStorage.getItem("supervisor") > 0) {
      api
        .get("sicoob/login/funcao/" + localStorage.getItem("agencia") + "/0", {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
        .then((response) => {
          const statusOptions = [];

          for (var i = 0; i < response.data.length; i++) {
            statusOptions.push({
              value: response.data[i].id,
              label: response.data[i].nome,
            });
          }
          statusOptions.push({ value: -1, label: "TODOS GERENTES" });
          setStatusOptions(statusOptions);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      statusOptions.push({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("nome"),
      });
      setSelectedOption(statusOptions);
    }
  }, []);

  function Buscar() {
    if (selectedOption == "" || dataini == "" || datafim == "") {
      handleError("Preencha todos campos");
      return false;
    }
    if (selectedOption.value === -1) {
      var url =
        "http://52.11.139.178/sicoob/cv/prospeccao/" +
        localStorage.getItem("agencia") +
        "/0/" +
        dataini +
        "/" +
        datafim;
    } else {
      var url =
        "http://52.11.139.178/sicoob/cv/prospeccao/" +
        localStorage.getItem("agencia") +
        "/" +
        selectedOption.value +
        "/" +
        dataini +
        "/" +
        datafim;
    }

    api
      .get(url, {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((res) => {
        if (res.data != "") {
          setClientes(res.data);
        }
      })
      .catch((error) => {
        var clientes = [];
        setClientes(clientes);
        handleError(error.response.data.message);
      });
  }
  function FiltrarCampos() {
    var input, filter, table, tr, td, i;
    input = document.getElementById("filtro");
    filter = input.value.toUpperCase();
    table = document.getElementById("table-to-xls");
    tr = table.getElementsByTagName("tr");
    for (var i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (var j = 0; j < tds.length; j++) {
        var td = tds[j];
        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  return (
    <>
      <div
        className="container"
        style={{ marginTop: "120px", marginBottom: "50px" }}
      >
        <div class="shadow-lg  mb-5 bg-white rounded card">
          <div class="card-header">
            <h3 className="font-weight-bold text-center">Busca de Eventos</h3>
          </div>
          <div class="card-body">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-2">
                    <label htmlFor="dataini">Data Inicial:</label>
                    <input
                      className="form-control"
                      id="dataini"
                      required="required"
                      type="date"
                      value={dataini}
                      placeholder="__/__/____"
                      onChange={(e) => setDataini(e.target.value)}
                    />
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="datafim">Data Final:</label>
                    <input
                      id="datafim"
                      required="required"
                      className="form-control"
                      required="required"
                      type="date"
                      value={datafim}
                      placeholder="__/__/____"
                      onChange={(e) => setDatafim(e.target.value)}
                    />
                  </div>
                  <div className="col-md-5">
                    <label htmlFor="supervisor">Gerente:</label>
                    <Select
                      id="supervisor"
                      value={selectedOption}
                      onChange={HandleChange}
                      options={statusOptions}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="btn_visualizar">&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn btn-primary form-control"
                      id="btn_visualizar"
                      onClick={Buscar}
                    >
                      {" "}
                      Visualizar&nbsp;<i className="fas fa-eye"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {clientes.length > 0 ? (
              <>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-success"
                  table="table-to-xls"
                  filename="Relatorio de Eventos"
                  sheet="tablexls"
                  buttonText="Export em XLS"
                />

                <div className="form-row" style={{ marginTop: "10px" }}>
                  <div className="col-md-4 ">
                    <label htmlFor="filtro">Filtrar Tabela:</label>
                    <input
                      id="filtro"
                      className="form-control"
                      onChange={(e) => FiltrarCampos()}
                      required="required"
                      type="text"
                      placeholder="Termo..."
                    />
                  </div>
                  <div className="col-md-8"></div>
                </div>
                <div id="customers" className="table-responsive">
                  <table
                    id="table-to-xls"
                    style={{ marginTop: "30px", marginBottom: "50px" }}
                    className="table table-hover table-bordered text-center"
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Cliente</th>
                        <th>Objetivo</th>
                        <th>Data</th>
                        <th>Gerente</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes.map((cliente, i) => {
                        return (
                          <tr key={i}>
                            <td>{cliente.cli_nome}</td>
                            <td>{cliente.descricao}</td>
                            <td>{mask_data(cliente.age_data)}</td>
                            <td>{cliente.nome}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { Component } from "react";
import PubSub from "pubsub-js";
import axios from "axios";
import Select from "react-select";
import { handleError, handleSuccess } from "../Plugins/Config/Config";
import { mask_tel_celular_DDD, mask_numeros } from "../Plugins/Mascara/Mascara";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
class FormProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      statusOptions: [],
      model: {
        id: "0",
        nome: "",
        senha: "",
        cracha: "",
        email: "",
        supervisor: "",
        celular: "",
      },
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    model.cracha = mask_numeros(model.cracha);
    model.celular = mask_tel_celular_DDD(model.celular);

    this.setState({ model });
  };
  create = (e) => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName("needs-validation");
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      if (e) {
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        form.classList.add("was-validated");
      }
    });

    const { model } = this.state;

    if (
      model.email !== "" &&
      model.senha !== "" &&
      model.nome !== "" &&
      model.cracha !== "" &&
      model.celular !== ""
    ) {
      this.setState({
        model: {
          id: 0,
          nome: "",
          email: "",
          senha: "",
          cracha: "",
          celular: "",
        },
      });
      this.props.productCreate(this.state.model);
    } else {
      handleError("Preencha todos campos");
      return false;
    }

    //console.log('o model que vai para save'+JSON.stringify(this.state.model));
  };
  // funcao para alterar o valor do campo select
  handleChange = (selectedOption) => {
    const { model } = this.state; // declarando o state model para poder ser utilizado
    model.supervisor = selectedOption.value; // atribuindo o valor da option selecionada ao state model equivalente ao campo
    this.setState({ model }); // salvando o  novo valor atribuido ao state em questao
    this.setState({ selectedOption }); // atualizando o state de  selectedOption
  };
  // componentDidMount eh o metodo que eh executado assim que a pagina eh carregada ou seja equivale a um evento onready do jquery o load do js puro
  componentDidMount = () => {
    //metodo ajax utilizando a dependencia axios

    var supervisor_array = [
      { supervisor_nome: "Gerente", supervisor_id: 0 },
      { supervisor_nome: "Supervisor", supervisor_id: 1 },
    ];
    const statusOptions = [];
    // construindo o corpo das options do select com as variaves vindas da api atraves do axios
    for (var i = 0; i < supervisor_array.length; i++) {
      statusOptions.push({
        value: supervisor_array[i].supervisor_id,
        label: supervisor_array[i].supervisor_nome,
      });
    }
    this.setState({ statusOptions }); // salvando o novo state
  };
  componentWillMount() {
    PubSub.subscribe("edit-product", (topic, product) => {
      //   console.log(JSON.stringify(product));
      var value = "";
      if (product.supervisor === "Supervisor") {
        value = 1;
      } else {
        value = 0;
      }
      this.setState({ model: product });
      this.setState({
        selectedOption: { value: value, label: product.supervisor },
      });
    });
  }

  render() {
    const { selectedOption, statusOptions } = this.state;

    return (
      <form className="needs-validation">
        <div className="form-group">
          <div className="form-row">
            <div className="col-md-4">
              <label htmlFor="cracha">Cracha:</label>
              <input
                id="cracha"
                className="form-control"
                required="required"
                type="text"
                value={this.state.model.cracha}
                placeholder="Insira o Cracha do gerente"
                onChange={(e) => this.setValues(e, "cracha")}
              />
              <div class="invalid-tooltip">Preencha o campo cracha.</div>
            </div>

            <div className="input-group col-md-4">
              <label
                for="cli_senha"
                style={{ width: "100%", color: "#7c7f82" }}
              >
                <b>Senha:</b>
              </label>
              <input
                id="senha"
                className="form-control"
                style={{
                  width: "100%",
                }}
                required
                type="password"
                autoComplete="new-password"
                value={this.state.model.senha}
                placeholder="Insira a senha"
                onChange={(e) => this.setValues(e, "senha")}
              />
              <div class="input-group-prepend">
                <div
                  style={{
                    color: "#1fa193",
                  }}
                  onClick={(e) =>
                    (document.getElementById("senha").type =
                      document.getElementById("senha").type == "text"
                        ? "password"
                        : "text")
                  }
                  class="input-group-text btn btn-outiline"
                >
                  <i class="far fa-eye"></i>
                </div>
              </div>
              <div class="invalid-tooltip">Preencha o campo senha.</div>
            </div>
            <div className="col-md-4">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                className="form-control"
                required="required"
                type="text"
                value={this.state.model.email}
                placeholder="Email..."
                onChange={(e) => this.setValues(e, "email")}
              />{" "}
              <div class="invalid-tooltip">Preencha o campo email.</div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="col-md-4">
              <label htmlFor="celular">Celular:</label>
              <input
                id="celular"
                className="form-control"
                required="required"
                type="text"
                value={this.state.model.celular}
                placeholder="celular..."
                onChange={(e) => this.setValues(e, "celular")}
              />
              <div class="invalid-tooltip">Preencha o campo celular.</div>
            </div>
            <div className="col-md-4 ">
              <label htmlFor="nome">Nome:</label>
              <input
                id="nome"
                className="form-control"
                required="required"
                type="text"
                value={this.state.model.nome}
                placeholder="Nome do gerente..."
                onChange={(e) => this.setValues(e, "nome")}
              />
              <div class="invalid-tooltip">Preencha o campo nome.</div>
            </div>
            <div className="col-md-4">
              <label htmlFor="supervisor">Função:</label>
              <Select
                style={{ zIndex: "999" }}
                required
                id="supervisor"
                value={selectedOption}
                onChange={this.handleChange}
                options={statusOptions}
              />
              <input
                style={{ marginTop: "-37px", zIndex: "1" }}
                id="supervisor_para_validar"
                className="form-control"
                required="required"
                type="text"
                value={selectedOption}
              />
              <div style={{ zIndex: "0" }} class="invalid-tooltip">
                Selecione a função deste gerente.
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="text-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) => this.create(e)}
          >
            {" "}
            Cadastrar&nbsp;<i className="fas fa-save"></i>{" "}
          </button>
        </div>
      </form>
    );
  }
}

class ListProduct extends Component {
  delete = (prod_codigo) => {
    this.props.deleteProduct(prod_codigo);
  };

  onEdit = (product) => {
    PubSub.publish("edit-product", product);
    document.getElementById("senha").disabled = true;
  };

  render() {
    const { products } = this.props;

    return (
      <div className="table-responsive">
        <table
          style={{ marginBottom: "50px" }}
          className="table table-hover table-bordered text-center"
        >
          <thead className="thead-dark">
            <tr>
              <th>Nome</th>
              <th>Login</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Função</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              // map eh equivalente a um for ou foreach em react
              products.map((product) => (
                <tr key={product.id}>
                  <td>{product.nome}</td>
                  <td>{product.cracha}</td>
                  <td>{product.celular}</td>
                  <td>{product.email}</td>
                  <td>{product.supervisor}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ fontSize: "14px" }}
                      onClick={(e) => this.onEdit(product)}
                    >
                      Editar <i className="fas fa-edit"></i>
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Voce tem certeza que deseja deletar o " +
                              product.supervisor +
                              ' "' +
                              product.nome +
                              '" ?'
                          )
                        ) {
                          this.delete(product.id);
                        }
                      }}
                    >
                      Deletar <i className="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default class ProductBox extends Component {
  // declando variaveis de states
  state = {
    products: [],

    message: {
      text: "",
      alert: "",
    },
  };

  componentDidMount() {
    //utilizando a dependencia axios para buscar os dados do Gerente atraves de ajax
    axios
      .get(
        "http://52.11.139.178/sicoob/login/" + localStorage.getItem("agencia"),
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((res) => {
        if (res.data !== "") {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].supervisor < 1) {
              res.data[i].supervisor = "Gerente";
            } else {
              res.data[i].supervisor = "Supervisor";
            }
          }
          this.setState({ products: res.data });
          //   console.log("res do axios "+JSON.stringify(res.data));
        }
      })
      .catch((error) => {
        handleError(error.response.data.message);
        console.log(error.response);
      });
  }

  //metodo save realiza o envio dos dados para api para insercao e alteracao dos dados
  // abaixo esta passando os dados cadasrados no formulario para o array data
  save = (product) => {
    if (product.supervisor == "Gerente" || product.supervisor == 0) {
      product.supervisor = 0;
    } else {
      product.supervisor = 1;
    }
    var celular = product.celular.split("+");
    let data = {
      id: parseInt(product.id),
      cracha: parseInt(product.cracha),
      email: String(product.email),
      senha: product.senha,
      nome: product.nome,
      celular: celular[1],
      supervisor: product.supervisor,
      agencia: parseInt(localStorage.getItem("agencia")),
    };

    if (product.id == 0) {
      // CREATE NEW PRODUCT
      // fetch('http://52.11.139.178/kitfesta/Gerente', requestInfo)  // esta linha envia a requisicao para a API
      axios({
        method: "post", //you can set what request you want to be
        url: "http://52.11.139.178/sicoob/login",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
        .then((response) => {
          // neste caso a api apenas retornou o id do Gerente que acabou de ser cadastrado

          let data1 = {
            // portanto para aparecer na tabela este Gerente devemos reutilizar os dados que foram enviados
            // com o acrescimo do id
            cracha: parseInt(product.cracha),
            email: String(product.email),
            senha: product.senha,
            nome: product.nome,
            celular: product.celular,
            supervisor: product.supervisor,
            agencia: parseInt(localStorage.getItem("agencia")),
          };
          //  console.log(JSON.stringify(data1));
          //  data.push(this.state);
          this.setState((state) => ({
            products: state.products.concat([data1]), // adicionando esse novo registro ao state de products, de forma de verdadeiramente aparacer na tabela de Gerentes
          }));

          let { products } = this.state;
          //  console.log("este eh o estado: "+JSON.stringify(products));
          //  products.push(newProduct);
          this.setState({ products });

          handleSuccess(response.data.message);
          document.getElementById("senha").disabled = false;
          axios
            .get(
              "http://52.11.139.178/sicoob/login/" +
                localStorage.getItem("agencia"),
              {
                auth: {
                  username: localStorage.getItem("username"),
                  password: localStorage.getItem("password"),
                },
              }
            )
            .then((res) => {
              if (res.data != "") {
                for (var i = 0; i < res.data.length; i++) {
                  if (res.data[i].supervisor < 1) {
                    res.data[i].supervisor = "Gerente";
                  } else {
                    res.data[i].supervisor = "Supervisor";
                  }
                }
                this.setState({ products: res.data });
                //   console.log("res do axios "+JSON.stringify(res.data));
              }
            });
        })
        .catch((error) => {
          handleError(error.response.data.message);
          console.log(error.response);
        });
    } else {
      // EDIT PRODUCT
      //  alert(JSON.stringify(data));
      axios({
        method: "put", //you can set what request you want to be
        url: "http://52.11.139.178/sicoob/login",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
        .then((response) => {
          let { products } = this.state;

          let position = products.findIndex(
            (product) => product.id !== data.id
          ); //alterando os dados da tabela

          this.setState({ products });
          handleSuccess(response.data.message);
          axios
            .get(
              "http://52.11.139.178/sicoob/login/" +
                localStorage.getItem("agencia"),
              {
                auth: {
                  username: localStorage.getItem("username"),
                  password: localStorage.getItem("password"),
                },
              }
            )
            .then((res) => {
              if (res.data != "") {
                for (var i = 0; i < res.data.length; i++) {
                  if (res.data[i].supervisor < 1) {
                    res.data[i].supervisor = "Gerente";
                  } else {
                    res.data[i].supervisor = "Supervisor";
                  }
                }
                this.setState({ products: res.data });
                //   console.log("res do axios "+JSON.stringify(res.data));
              }
            });
          // this.setState({ products, message: { text: 'Gerente atualizado com sucesso!', alert: 'info' } });
          // this.timerMessage(3000);
        })
        .catch((error) => {
          handleError(error.response.data.message);
          console.log(error.response);
        });
    }
  };

  delete = (id) => {
    axios({
      method: "DELETE", //you can set what request you want to be
      url: "http://52.11.139.178/sicoob/login/" + id,
      data: id,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        const products = this.state.products.filter(
          (product) => product.id !== id
        );
        //  this.setState({ products,  message: { text: 'Gerente deletado com sucesso.', alert: 'danger' } });
        //  this.timerMessage(3000);
        this.setState({ products });
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error.response.data.message);
        console.log(error.response);
        return false;
      });
  };
  // metodo responsavel pelas mensagens de sucesso e erro
  timerMessage = (duration) => {
    setTimeout(() => {
      this.setState({ message: { text: "", alert: "" } });
    }, duration);
  };

  render() {
    return (
      <div className="container">
        {
          this.state.message.text !== "" ? (
            <alert color={this.state.message.alert} className="text-center">
              {" "}
              {this.state.message.text}{" "}
            </alert>
          ) : (
            ""
          )

          // abaixo eh visto o instanciamento dos componentes FormProduct e ListProduct
        }

        <div className="row">
          <div className="col-md-12 ">
            <div class="shadow-lg  mb-5 bg-white rounded card">
              <div class="card-header">
                <h3 className="font-weight-bold text-center">
                  {" "}
                  Cadastro de Gerentes{" "}
                </h3>
              </div>
              <div class="card-body">
                <FormProduct productCreate={this.save} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12 ">
            <div class="shadow-lg  mb-5 bg-white rounded  card">
              <div class="card-header">
                <h2 className="font-weight-bold text-center">
                  {" "}
                  Lista de Gerentes{" "}
                </h2>
              </div>
              <div class="card-body">
                <ListProduct
                  products={this.state.products}
                  deleteProduct={this.delete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

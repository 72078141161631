import { Component } from 'react';

export default class Logout extends Component {

    componentWillMount() {        
      
        localStorage.removeItem('id');
        localStorage.removeItem('email');
        localStorage.removeItem('senha');
        localStorage.removeItem('agencia');
        localStorage.removeItem('supervisor');
        localStorage.removeItem('nome');
      
        this.props.history.push('/');
     
    }

    render() {
        return null;
    }
}
import React, { Component } from "react";
import chroma from "chroma-js";
import Select from "react-select";
import { api, handleError, handleSuccess } from "../Plugins/Config/Config";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
class Transferir extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objetivo: {
        id: 0,
        descricao: "",
        situacao: "",
        cor: "",
        novacor: "",
      },
      status: true,
      objetivos: [],
      status_array: [],
      status_objetivo: [],
      selectedOption: null,
      statusOptions: [
        { value: "#000000", label: "Cadastrar nova cor", color: "#000000" },
        { value: "#1abc9c", label: "#1abc9c", color: "#1abc9c" },
        { value: "#16a085", label: "#16a085", color: "#16a085" },
        { value: "#2ecc71", label: "#2ecc71", color: "#2ecc71" },
        { value: "#27ae60", label: "#27ae60", color: "#27ae60" },
        { value: "#3498db", label: "#3498db", color: "#3498db" },
        { value: "#2980b9", label: "#2980b9", color: "#2980b9" },
        { value: "#f1c40f", label: "#f1c40f", color: "#f1c40f" },
        { value: "#f39c12", label: "#f39c12", color: "#f39c12" },
        { value: "#e67e22", label: "#e67e22", color: "#e67e22" },
        { value: "#d35400", label: "#d35400", color: "#d35400" },
        { value: "#e74c3c", label: "#e74c3c", color: "#e74c3c" },
        { value: "#c0392b", label: "#c0392b", color: "#c0392b" },
        { value: "#9b59b6", label: "#9b59b6", color: "#9b59b6" },
        { value: "#8e44ad", label: "#8e44ad", color: "#8e44ad" },
        { value: "#bdc3c7", label: "#bdc3c7", color: "#bdc3c7" },
        { value: "#2c3e50", label: "#2c3e50", color: "#2c3e50" },
        { value: "#34495e", label: "#34495e", color: "#34495e" },
        { value: "#95a5a6", label: "#95a5a6", color: "#95a5a6" },
        { value: "#7f8c8d", label: "#7f8c8d", color: "#7f8c8d" },
        { value: "#81ecec", label: "#81ecec", color: "#81ecec" },
        { value: "#00cec9", label: "#00cec9", color: "#00cec9" },
        { value: "#fab1a0", label: "#fab1a0", color: "#fab1a0" },
        { value: "#e17055", label: "#e17055", color: "#e17055" },
        { value: "#d63031", label: "#d63031", color: "#d63031" },
        { value: "#ff7675", label: "#ff7675", color: "#ff7675" },
        { value: "#0984e3", label: "#0984e3", color: "#0984e3" },
        { value: "#74b9ff", label: "#74b9ff", color: "#74b9ff" },
        { value: "#a29bfe", label: "#a29bfe", color: "#a29bfe" },
        { value: "#6c5ce7", label: "#6c5ce7", color: "#6c5ce7" },
        { value: "#fd79a8", label: "#fd79a8", color: "#fd79a8" },
        { value: "#e84393", label: "#e84393", color: "#e84393" },
        { value: "#b2bec3", label: "#b2bec3", color: "#b2bec3" },
        { value: "#636e72", label: "#636e72", color: "#636e72" },
        { value: "#2d3436", label: "#2d3436", color: "#2d3436" },
      ],
    };
  }

  validalength = (e, field) => {
    const { objetivo } = this.state;
    if (e.target.value.length !== 7) {
      objetivo.novacor = "";
      this.setState({ objetivo });
    }
  };
  Claridade = (hexadecimal) => {
    var hex = String(hexadecimal);
    var nib = hex.split("");

    var r = parseInt(nib[1] + nib[2], 16);
    var g = parseInt(nib[3] + nib[4], 16);
    var b = parseInt(nib[5] + nib[6], 16);

    var luminosidade = (r * 299 + g * 587 + b * 114) / 1000;
    //128+ é considerado cor clara
    if (luminosidade > 205) {
      return 0;
    } else {
      return 1;
    }
  };
  Simb_prefixo = (v, prefixo) => {
    var index = v.indexOf(String(prefixo));
    if (index <= -1) {
      return prefixo + v;
    } else {
      return v;
    }
  };
  backgroundColor_NovaCor = (cor, campo_id) => {
    if (cor.length === 7) {
      document.getElementById(campo_id).style.backgroundColor = cor;
      document.getElementById(campo_id).style.color = "white";
      document.getElementById(campo_id).style.border = "1px solid " + cor;
      document.getElementById(campo_id).style.boxShadow = "0 0 0 0.2rem " + cor;
    } else {
      document.getElementById(campo_id).style.backgroundColor = "white";
      document.getElementById(campo_id).style.color = "#495057";
      document.getElementById(campo_id).style.border = "1px solid #ced4da";
      document.getElementById(campo_id).style.boxShadow = "";
    }
  };
  tamanho_limit = (v, tamanho_max, field) => {
    if (field === "novacor") {
      if (v.length > tamanho_max) {
        return v.substring(0, tamanho_max);
      } else {
        return v;
      }
    }
  };

  setValues = (e, field) => {
    const { objetivo } = this.state;
    objetivo[field] = e.target.value;
    if (objetivo.novacor !== "") {
      objetivo.novacor = this.tamanho_limit(
        this.Simb_prefixo(objetivo.novacor, "#"),
        7,
        field
      );
      this.backgroundColor_NovaCor(objetivo.novacor, "novacor");
    }
    //   console.log(objetivo.novacor);
    this.setState({ objetivo });
  };

  cancelar = () => {
    this.setState({ status: true });
    const { objetivo } = this.state;
    objetivo.novacor = "";
    this.setState({ objetivo });
  };

  Status_PUT = (item, tipo) => {
    var status = "";
    if (item.situacao === "A") {
      status = "I";
    } else {
      status = "A";
    }
    let data = {
      id: parseInt(item.id),
      descricao: item.descricao,
      cor: item.cor,
      situacao: status,
      agencia: parseInt(localStorage.getItem("agencia")),
    };

    api({
      method: "put", //you can set what request you want to be
      url: "sicoob/objetivo",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        if (tipo > 0) {
          this.handleInfo(response.data.message);
        } else {
          handleSuccess(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        handleError(error.response.data.message);
        return false;
      });
  };
  Transferir = (item, e) => {
    e.preventDefault();
    const tipo = 1;

    const status_array = this.state.status_array;
    status_array.push(item.id);
    this.setState({ status_array });

    this.Status_PUT(item, tipo);
  };

  Desfazer = (item, e) => {
    e.preventDefault();
    const tipo = 0;
    this.setState({ status: false });
    const status_array = this.state.status_array;
    var index = status_array.indexOf(item.id);
    if (index > -1) {
      status_array.splice(index, 1);
    }
    this.setState({ status_array });
    this.Status_PUT(item, tipo);
  };

  editar = (item, e) => {
    // this.setState({ objetivo: item });
    this.cancelar();
    this.setState({
      selectedOption: { value: item.cor, label: item.cor, color: item.cor },
    });
    this.setState({
      objetivo: {
        descricao: item.descricao,
        cor: item.cor,
        novacor: item.novacor,
        situacao: item.situacao,
        id: item.id,
      },
    });
  };
  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    if (this.Claridade(color) === 0 || color === NaN) {
      this.getRandomColor();
    } else {
      return color;
    }
  };

  save = (objetivo, e) => {
    var cor = "";
    if (
      (typeof objetivo.novacor == "undefined" &&
        typeof objetivo.cor == "undefined") ||
      (objetivo.cor.length < 7 && objetivo.novacor.length < 7)
    ) {
      cor = this.getRandomColor();
    } else {
      if (objetivo.novacor) {
        cor = objetivo.novacor;
      } else {
        cor = objetivo.cor;
      }
    }
    if (objetivo.id !== "" && objetivo.descricao !== "" && cor !== "") {
      this.cancelar();
      let data = {
        id: parseInt(objetivo.id),
        descricao: objetivo.descricao,
        cor: cor,
        situacao: objetivo.situacao,
        agencia: parseInt(localStorage.getItem("agencia")),
      };
      this.setState({
        objetivo: { id: 0, descricao: "", cor: "", situacao: "", novacor: "" },
      });
      this.setState({
        selectedOption: { value: null, label: null, color: null },
      });
      // console.log('data eh: ' + JSON.stringify(data));
      //  console.log('status eh: ' + JSON.stringify(product.cli_fone));
      if (objetivo.id === 0) {
        // CREATE NEW PRODUCT

        api({
          method: "post",
          url: "sicoob/objetivo",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
          .then((response) => {
            this.setState((state) => ({
              objetivos: state.objetivos.concat([data]), // adicionando esse novo registro ao state de products, de forma de verdadeiramente aparacer na tabela de Gerentes
            }));

            // let { products } = this.state;
            //   console.log("este eh o estado: "+JSON.stringify(products));
            //  products.push(newProduct);
            //  this.setState({ products });
            handleSuccess(response.data.message);
            api
              .get(
                "sicoob/objetivo/" + localStorage.getItem("agencia") + "/T",
                {
                  auth: {
                    username: localStorage.getItem("username"),
                    password: localStorage.getItem("password"),
                  },
                }
              )
              .then((response) => {
                this.setState({ objetivos: response.data });
              })
              .catch((error) => {
                const objetivos = [];
                this.setState({ objetivos });
                handleError(error.response.data.message);
                return false;
              });

            // this.setState({ products, message: { text: 'Novo Gerente adicionado com sucesso!', alert: 'success' } });
            // this.timerMessage(3000);
          })
          .catch((error) => {
            handleError(error.response.data.message);
            console.log(error.response);
            return false;
          });
      } else {
        // EDIT PRODUCT

        api({
          method: "put", //you can set what request you want to be
          url: "sicoob/objetivo",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
          .then((response) => {
            let { objetivos } = this.state;

            //   let position = objetivos.findIndex(item => item.id !== data.id); //alterando os dados da tabela

            this.setState({ objetivos });
            handleSuccess(response.data.message);
            api
              .get(
                "sicoob/objetivo/" + localStorage.getItem("agencia") + "/T",
                {
                  auth: {
                    username: localStorage.getItem("username"),
                    password: localStorage.getItem("password"),
                  },
                }
              )
              .then((response) => {
                this.setState({ objetivos: response.data });
              })
              .catch((error) => {
                const objetivos = [];
                this.setState({ objetivos });
                handleError(error.response.data.message);
                return false;
              });
            // this.setState({ products, message: { text: 'Gerente atualizado com sucesso!', alert: 'info' } });
            // this.timerMessage(3000);
          })
          .catch((error) => {
            handleError(error.response.data.message);
            return false;
          });
      }
    } else {
      handleError("Preencha no mínimo o campo nome. ");
    }
  };

  componentDidMount = () => {
    api
      .get("sicoob/objetivo/" + localStorage.getItem("agencia") + "/T", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].situacao === "I") {
            const status_array = this.state.status_array;
            status_array.push(response.data[i].id);
            this.setState({ status_array });
          }
        }

        // construindo o corpo das options do select com as variaves vindas da api atraves do axios
        this.setState({ objetivos: response.data });
      })
      .catch((error) => {
        const objetivos = [];
        this.setState({ objetivos });
        handleError("Nenhum objetivo cadastrado");
        return false;
      });
  };
  handleChange = (selectedOption) => {
    const { objetivo } = this.state;
    this.setState({ selectedOption });
    objetivo.cor = selectedOption.value;
    if (selectedOption.label === "Cadastrar nova cor") {
      this.setState({ status: false });
    }
    /* var luminosidade  = this.Claridade(selectedOption.value);
         if(luminosidade == 0 ){
             handleError('esta cor é muito clara');
         }else{
            this.handleInfo('esta cor é boa');
         }*/
    this.setState({ objetivo });
  };

  render() {
    const { selectedOption, statusOptions } = this.state;
    //console.log(statusOptions.color)
    const dot = (color = "#ccc") => ({
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: !isDisabled
            ? data.color
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.3).css()
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : "white",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      placeholder: (styles) => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    //console.log(colourStyles);
    //console.log(JSON.stringify(colourStyles));
    const { objetivos } = this.state;

    return (
      <>
        <div className="container">
          <div class="shadow-lg  mb-5 bg-white rounded card">
            <div class="card-header">
              <h3 className="font-weight-bold text-center">
                Cadastro de Objetivos de Visitas
              </h3>
            </div>

            <div class="card-body">
              <form style={{ marginBottom: "30px" }}>
                <legend style={{ fontSize: "14px", marginBottom: "20px" }}>
                  Cadastre o objetivo de suas visitas no campo "nome" assim como
                  no modo de edição a troca de cores do calendário referente ao
                  objetivo será possível por esta tela :
                </legend>
                {this.state.status ? (
                  <>
                    <div className="form-row">
                      <div className="col-md-4 ">
                        <label htmlFor="descricao">Nome:</label>
                        <input
                          id="descricao"
                          className="form-control"
                          required="required"
                          type="text"
                          value={this.state.objetivo.descricao}
                          placeholder="Nome do objetivo..."
                          onChange={(e) => this.setValues(e, "descricao")}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="cores">Cor:</label>
                        <Select
                          value={selectedOption}
                          defaultValue={statusOptions[2]}
                          options={statusOptions}
                          styles={colourStyles}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          title="Cadastrar"
                          className="btn btn-primary"
                          style={{ marginTop: "16%" }}
                          onClick={(e) => this.save(this.state.objetivo)}
                        >
                          Cadastrar <i className="fas fa-save"></i>
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-row">
                      <div className="col-md-4 ">
                        <label htmlFor="descricao">Nome:</label>
                        <input
                          id="descricao"
                          className="form-control"
                          required="required"
                          type="text"
                          value={this.state.objetivo.descricao}
                          placeholder="Nome do objetivo..."
                          onChange={(e) => this.setValues(e, "descricao")}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="novacor">Nova Cor:</label>
                        <input
                          id="novacor"
                          className="form-control"
                          required="required"
                          type="text"
                          onBlur={(e) => this.validalength(e, "novacor")}
                          value={this.state.objetivo.novacor}
                          placeholder="#0000000"
                          onChange={(e) => this.setValues(e, "novacor")}
                        />
                      </div>

                      <div className="col-md-2">
                        <button
                          type="button"
                          title="cancelar"
                          className="btn btn-danger"
                          onClick={(e) => this.cancelar()}
                          style={{ marginTop: "16%" }}
                        >
                          Cancelar <i className="fas fa-times"></i>
                        </button>
                      </div>

                      <div className="col-md-2">
                        <button
                          type="button"
                          title="Cadastrar"
                          className="btn btn-primary"
                          style={{ marginTop: "16%" }}
                          onClick={(e) => this.save(this.state.objetivo)}
                        >
                          Cadastrar <i className="fas fa-save"></i>
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </>
                )}
              </form>

              {objetivos.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table
                      style={{ marginBottom: "50px" }}
                      className="table table-hover table-bordered text-center"
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th>Nome</th>
                          <th>Cor</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          // map eh equivalente a um for ou foreach em react
                          objetivos.map((item) => (
                            <tr key={item.id}>
                              <td>{item.descricao}</td>
                              <td
                                style={{
                                  backgroundColor: String(item.cor),
                                  color: "white",
                                }}
                              >
                                {item.cor}
                              </td>
                              {this.state.status_array.indexOf(item.id) !==
                              -1 ? (
                                <td>Inativo</td>
                              ) : (
                                <td>Ativo</td>
                              )}
                              <td>
                                <button
                                  type="button"
                                  title="Editar"
                                  className="btn btn-primary"
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                  }}
                                  onClick={(e) => this.editar(item)}
                                >
                                  Editar&nbsp;<i className="fas fa-edit"></i>
                                </button>
                                &nbsp;&nbsp;
                                {this.state.status_array.indexOf(item.id) !==
                                -1 ? (
                                  <button
                                    type="submit"
                                    title="Editar"
                                    className="btn btn-danger"
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "10px",
                                    }}
                                    onClick={(e) => this.Desfazer(item, e)}
                                  >
                                    {" "}
                                    Ativar&nbsp;
                                    <i className="fas fa-exchange-alt"></i>
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    title="Editar"
                                    className="btn btn-primary"
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "10px",
                                    }}
                                    onClick={(e) => this.Transferir(item, e)}
                                  >
                                    {" "}
                                    Desativar&nbsp;
                                    <i className="fas fa-exchange-alt"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Transferir;

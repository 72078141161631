import React from "react";
import { api, handleError, handleInfo } from "../Plugins/Config/Config";
import { mask_data } from "../Plugins/Mascara/Mascara";
import Select from "react-select";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";

class MyMapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waypts: [],
      selectedOption: null,
      statusOptions: [],
      status: false,

      model: {
        dataini: "",
        datafim: "",
        supervisor: "",
        supervisor_label: "",
      },
      stores: [],
    };
  }

  setValues = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;

    this.setState({ model });
  };

  validanull = (e, field) => {
    const { model } = this.state;
    model[field] = e.target.value;
    if (model.dataini.length === 10) {
      model.dataini = model.dataini;
      this.setState({ model });
    } else {
      model.dataini = "";
      this.setState({ model });
    }
    if (model.datafim.length === 10) {
      model.datafim = model.datafim;
      this.setState({ model });
    } else {
      model.datafim = "";
      this.setState({ model });
    }
  };

  LimparCampos = () => {
    const waypts = [];
    this.setState({ selectedOption: null });
    this.setState({
      model: { dataini: "", datafim: "", supervisor: "", supervisor_label: "" },
    });
    this.setState({ waypts });
    this.setState({ status: false });
    document.getElementById("total").textContent = "";
    document.getElementById("inicio").textContent = "";
    document.getElementById("destino").textContent = "";
    document.getElementById("tempo").textContent = "";
    document.getElementById("pontos").innerHTML = "";
  };

  create = () => {
    const { model } = this.state;
    if (localStorage.getItem("supervisor") < 1) {
      model.supervisor = localStorage.getItem("id");
      this.setState({ model });
    }

    console.log(JSON.stringify(model));
    // console.log(this.state.model);

    if (
      model.dataini !== "" &&
      model.datafim !== "" &&
      model.supervisor !== ""
    ) {
      // this.setState({ model: { id: 0,  nome: '', email: '', senha: ''} })
      this.setState({ status: true });
    } else {
      handleError("Preencha todos campos");
      return false;
    }
  };
  // funcao para alterar o valor do campo select
  handleChange = (selectedOption) => {
    const { model } = this.state;
    model.supervisor = selectedOption.value;
    model.supervisor_label = selectedOption.label;
    this.setState({ model });

    this.setState({ selectedOption });
  };

  GetGerente = () => {
    if (localStorage.getItem("supervisor") > 0) {
      api
        .get("sicoob/login/funcao/" + localStorage.getItem("agencia") + "/0", {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
        .then((response) => {
          // console.log(response);
          //  console.log('response: ' + JSON.stringify(response.data));
          const statusOptions = [];
          // construindo o corpo das options do select com as variaves vindas da api atraves do axios
          for (var i = 0; i < response.data.length; i++) {
            statusOptions.push({
              value: response.data[i].id,
              label: response.data[i].nome,
            });
          }
          //  statusOptions.push({ value: -1, label: 'TODOS GERENTES' });
          this.setState({ statusOptions }); // salvando o novo state
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        selectedOption: {
          value: localStorage.getItem("id"),
          label: localStorage.getItem("nome"),
        },
      });
    }
  };

  // componentDidMount eh o metodo que eh executado assim que a pagina eh carregada ou seja equivale a um evento onready do jquery o load do js puro
  componentDidMount = () => {
    this.GetGerente();
  };

  render() {
    const { selectedOption, statusOptions } = this.state;

    const { status } = this.state;
    const { model } = this.state;

    const DirectionsComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyBWQHc9r61osjF-vtiP8IYcCB_8j5Cp8gw",
        loadingElement: <div style={{ height: `400px` }} />,
        containerElement: <div style={{ width: `100%` }} />,
        mapElement: (
          <div
            className="col-md-12"
            style={{ height: "620px", width: "100%", position: "relative" }}
          />
        ),
      }),
      withScriptjs,
      withGoogleMap,
      lifecycle({
        componentDidMount() {
          if (status === true) {
            api
              .get(
                "sicoob/login/trajeto/" +
                  model.supervisor +
                  "/" +
                  mask_data(String(model.dataini)) +
                  "/" +
                  mask_data(String(model.datafim)),
                {
                  auth: {
                    username: localStorage.getItem("username"),
                    password: localStorage.getItem("password"),
                  },
                }
              )
              .then((response) => {
                if (response.data.length > 0) {
                  var pular = parseInt((response.data.length - 2) / 23 + 1);
                  console.log(pular);
                  const waypts = [];
                  var cont = 1;
                  for (var i = 1; i < response.data.length - 1; i = i + pular) {
                    console.log(cont);
                    waypts.push({
                      location: {
                        lat: parseFloat(
                          response.data[i].ct_latitude.replace(",", ".")
                        ),
                        lng: parseFloat(
                          response.data[i].ct_longitude.replace(",", ".")
                        ),
                      },
                      stopover: true,
                    });
                    cont++;
                  }

                  this.setState({ waypts });

                  const DirectionsService = new window.google.maps.DirectionsService();
                  DirectionsService.route(
                    {
                      origin: new window.google.maps.LatLng(
                        parseFloat(
                          response.data[0].ct_latitude.replace(",", ".")
                        ),
                        parseFloat(
                          response.data[0].ct_longitude.replace(",", ".")
                        )
                      ),
                      waypoints: waypts,
                      destination: new window.google.maps.LatLng(
                        parseFloat(
                          response.data[
                            response.data.length - 1
                          ].ct_latitude.replace(",", ".")
                        ),
                        parseFloat(
                          response.data[
                            response.data.length - 1
                          ].ct_longitude.replace(",", ".")
                        )
                      ),
                      travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                        var route = result.routes[0];
                        if (route.legs.length > 0) {
                          var total = 0;
                          var destino = "";
                          var tempo = 0;
                          var pontos = "";
                          var letra = "";
                          for (var i = 0; i < route.legs.length; i++) {
                            var routeSegment = i + 1;

                            total += parseFloat(route.legs[i].distance.value);
                            destino = route.legs[i].end_address;
                            tempo += parseInt(route.legs[i].duration.value);

                            switch (routeSegment) {
                              case 1:
                                letra = "A";
                                break;
                              case 2:
                                letra = "B";
                                break;
                              case 3:
                                letra = "C";
                                break;
                              case 4:
                                letra = "D";
                                break;
                              case 5:
                                letra = "E";
                                break;
                              case 6:
                                letra = "F";
                                break;
                              case 7:
                                letra = "G";
                                break;
                              case 8:
                                letra = "H";
                                break;
                              case 9:
                                letra = "I";
                                break;
                              case 10:
                                letra = "J";
                                break;
                              case 11:
                                letra = "K";
                                break;
                              case 12:
                                letra = "L";
                                break;
                              case 13:
                                letra = "M";
                                break;
                              case 14:
                                letra = "N";
                                break;
                              case 15:
                                letra = "O";
                                break;
                              case 16:
                                letra = "P";
                                break;
                              case 17:
                                letra = "Q";
                                break;
                              case 18:
                                letra = "R";
                                break;
                              case 19:
                                letra = "S";
                                break;
                              case 20:
                                letra = "T";
                                break;
                              case 21:
                                letra = "U";
                                break;
                              case 22:
                                letra = "V";
                                break;
                              case 23:
                                letra = "W";
                                break;
                              case 24:
                                letra = "X";
                                break;
                              case 25:
                                letra = "Y";
                                break;
                            }
                            pontos =
                              pontos +
                              '<option value="' +
                              i +
                              '"><b style={{backgroundColor:"#000"}}>' +
                              letra +
                              " -- </b>" +
                              route.legs[i].start_address +
                              "</option>";
                          }

                          total = total / 1000;
                          tempo = parseInt(tempo / 60);

                          document.getElementById("total").textContent =
                            total + " km";
                          document.getElementById("inicio").textContent =
                            route.legs[0].start_address;
                          document.getElementById(
                            "destino"
                          ).textContent = destino;
                          document.getElementById("tempo").textContent =
                            tempo + " min";
                          document.getElementById("pontos").innerHTML = pontos;

                          // construindo o corpo das options do select com as variaves vindas da api atraves do axios

                          this.setState({
                            directions: { ...result },
                            markers: true,
                          });
                        }
                      } else {
                        console.error(`error fetching directions ${result}`);
                      }
                    }
                  );
                } else {
                  //const waypts = []
                  // this.setState({ waypts });
                  handleInfo(response.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
                handleInfo(error.response.data.message);
              });
          }
        },
      })
    )((props) => (
      <GoogleMap
        defaultZoom={3}
        defaultCenter={new window.google.maps.LatLng(-22.186465, -47.389675)}
      >
        {props.directions && (
          <DirectionsRenderer
            directions={props.directions}
            suppressMarkers={props.markers}
          />
        )}
      </GoogleMap>
    ));

    return (
      <>
        <div className="container">
          <div class="shadow-lg  mb-5 bg-white rounded card">
            <div class="card-header">
              <h3 className="font-weight-bold text-center">Rastreamento</h3>
            </div>
            <div class="card-body">
              <form style={{ marginTop: "30px" }}>
                <div className="form-row">
                  <div className="col-md-2">
                    <label htmlFor="dataini">Data Inicial:</label>
                    <input
                      id="dataini"
                      className="form-control"
                      required="required"
                      type="date"
                      value={this.state.model.dataini}
                      onChange={(e) => this.setValues(e, "dataini")}
                    />
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="datafim">Data Final:</label>
                    <input
                      id="datafim"
                      className="form-control"
                      required="required"
                      type="date"
                      value={this.state.model.datafim}
                      onChange={(e) => this.setValues(e, "datafim")}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="supervisor">Gerente:</label>
                    <Select
                      id="supervisor"
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={statusOptions}
                    />
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_visualizar">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <button
                      type="button"
                      className="btn btn-primary form-control"
                      id="btn_visualizar"
                      onClick={this.create}
                    >
                      Rastrear &nbsp;<i className="fas fa-map-marker-alt"></i>{" "}
                    </button>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="btn_limpar">&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn btn-primary form-control"
                      id="btn_limpar"
                      onClick={this.LimparCampos}
                    >
                      Limpar&nbsp;<i className="fas fa-trash-alt"></i>{" "}
                    </button>
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "45px" }}>
                  <div className="col-md-7" style={{ position: "inherit" }}>
                    <DirectionsComponent />
                  </div>
                  <div
                    className="col-md-5"
                    style={{
                      backgroundColor: "#cacaca",
                      position: "inherit",
                      height: "620px",
                    }}
                  >
                    <h2 className="text-center text-dark">
                      Detalhes da visita
                    </h2>
                    <div className="list-group" style={{ fontSize: "20px" }}>
                      <a className="list-group-item list-group-item-action">
                        <strong>Distancia Total:</strong> <b id="total"></b>
                      </a>

                      <a className="list-group-item list-group-item-action ">
                        <strong>Tempo:</strong> <span id="tempo"></span>
                      </a>
                      <a className="list-group-item list-group-item-action ">
                        <strong>Localizacão Inicial:</strong>{" "}
                        <span id="inicio"></span>
                      </a>
                      <a className="list-group-item list-group-item-action ">
                        <strong>Destino:</strong> <span id="destino"></span>
                      </a>
                    </div>

                    <div className="col-md-12">
                      <h2
                        className="text-center text-dark"
                        style={{ fontSize: "25px" }}
                      >
                        Pontos Marcados
                      </h2>
                      <select id="pontos" className="form-control">
                        <option value="-1">
                          Endereços referente aos pontos marcados
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </>
    );
  }
}
export default MyMapComponent;

import React, { useState, useEffect } from "react";
import {
  api,
  handleError,
  handleSuccess,
  handleInfo,
  handleWarn,
} from "../Plugins/Config/Config";
import Select from "react-select";
// os import acima sao as chamadas de dependencia a serem utlizadas nesta pagina, tambem podem ser visto com plugin igual do jquery
export default function Transferir() {
  const [selectedOption, setSelectedOption] = useState([""]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedGerente, setSelectedGerente] = useState([""]);
  const [status, setStatus] = useState([false]);
  const [status_array, setstatus_array] = useState([]);
  const [clientes, setClientes] = useState([]);

  function Transferir_PUT(cli_codigo, id, tipo) {
    let data = {
      id: id,
      cli_codigo: cli_codigo,
    };
    api({
      method: "put", //you can set what request you want to be
      url: "sicoob/cliente/altera/" + cli_codigo + "/" + id,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
    })
      .then((response) => {
        if (tipo > 0) {
          handleInfo("Cliente Transferido com sucesso!");
        } else {
          handleSuccess("Transferência Cancelada!");
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("Falha ao executar");
        return false;
      });
  }

  function Transferir(cli_codigo, e) {
    // e.preventDefault();
    const tipo = 1;
    //const { selectedGerente } = this.state;
    // const { selectedOption } = this.state;

    if (
      selectedOption.value !== selectedGerente.value &&
      selectedOption !== "" &&
      selectedGerente !== ""
    ) {
      setStatus(true);
      //  const status_array = this.state.status_array;
      // construindo o corpo das options do select com as variaves vindas da api atraves do axios

      //  setstatus_array(...status_array,cli_codigo);
      setstatus_array([...status_array, cli_codigo]);
      Transferir_PUT(cli_codigo, selectedGerente.value, tipo);
    } else {
      handleError("Selecione dois gerentes diferentes ");
    }
  }

  function Desfazer(cli_codigo, e) {
    e.preventDefault();
    const tipo = 0;
    //const { selectedOption } = this.state;
    //  const { selectedGerente } = this.state;
    if (
      selectedOption.value !== selectedGerente.value &&
      selectedOption !== "" &&
      selectedGerente !== ""
    ) {
      setStatus(false);
      //    const status_array = this.state.status_array;
      var index = status_array.indexOf(cli_codigo);
      if (index > -1) {
        status_array.splice(index, 1);
        console.log(status_array);
        //  setstatus_array(status_array.splice(index, 1));
        setstatus_array(status_array);
      }

      // const { selectedOption } = this.state;

      Transferir_PUT(cli_codigo, selectedOption.value, tipo);
    } else {
      handleWarn("Selecione dois gerentes diferentes ");
    }
  }

  // funcao para alterar o valor do campo select
  function handleChange(selectedOption) {
    setSelectedOption(selectedOption); // atualizando o state de  selectedOption

    const status_array = [];
    setstatus_array(status_array);
    api
      .get(
        "sicoob/cliente/" +
          localStorage.getItem("agencia") +
          "/" +
          selectedOption.value +
          "/",
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setClientes(response.data);
      })
      .catch((error) => {
        const clientes = [];
        setClientes(clientes);
        handleError("Nenhum cliente cadastrado");
        return false;
      });
  }

  useEffect(() => {
    if (localStorage.getItem("supervisor") > 0) {
      api
        .get("sicoob/login/funcao/" + localStorage.getItem("agencia") + "/0", {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        })
        .then((response) => {
          const statusOptions = [];
          // construindo o corpo das options do select com as variaves vindas da api atraves do axios
          for (var i = 0; i < response.data.length; i++) {
            statusOptions.push({
              value: response.data[i].id,
              label: response.data[i].nome,
            });
          }
          //  statusOptions.push({ value: -1, label: 'TODOS GERENTES' });
          setStatusOptions(statusOptions); // salvando o novo state
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      statusOptions.push({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("nome"),
      });

      setSelectedOption(statusOptions);
    }
  }, []);

  function handleChangeNovoGerente(selectedGerente) {
    setSelectedGerente(selectedGerente);
  }

  function FiltrarCampos() {
    var input, filter, table, tr, td;
    input = document.getElementById("filtro");
    filter = input.value.toUpperCase();
    table = document.getElementById("transferir");
    tr = table.getElementsByTagName("tr");
    for (let i = 1; i < tr.length; i++) {
      var tds = tr[i].getElementsByTagName("td");
      var flag = false;
      for (let j = 0; j < tds.length; j++) {
        td = tds[j];
        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
          flag = true;
        }
      }
      if (flag) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  console.log(status_array);
  return (
    <>
      <div className="container">
        <div class="shadow-lg  mb-5 bg-white rounded card">
          <div class="card-header">
            <h3 className="font-weight-bold text-center">Transferir Cliente</h3>
          </div>

          <div class="card-body">
            <form style={{ marginTop: "30px", marginBottom: "30px" }}>
              <legend style={{ fontSize: "14px", marginBottom: "20px" }}>
                Selecione um gerente para assim possibilitar a escolha de quais
                clientes deste gerente serão transferidos para o novo gerente:
              </legend>
              <div className="form-row">
                <div className="col-md-5">
                  <label htmlFor="gerente">Gerente:</label>
                  <Select
                    id="gerente"
                    required
                    value={selectedOption}
                    onChange={handleChange}
                    options={statusOptions}
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="NovoGerente">Novo Gerente:</label>
                  <Select
                    id="NovoGerente"
                    required
                    value={selectedGerente}
                    onChange={handleChangeNovoGerente}
                    options={statusOptions}
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
            </form>

            {clientes.length > 0 ? (
              <>
                <div className="form-row">
                  <div className="col-md-4 ">
                    <label htmlFor="filtro">Filtrar Tabela:</label>
                    <input
                      id="filtro"
                      className="form-control"
                      onChange={(e) => FiltrarCampos()}
                      required="required"
                      type="text"
                      placeholder="Termo..."
                    />
                  </div>

                  <div className="col-md-8"></div>
                </div>
                <div className="table-responsive">
                  <table
                    style={{ marginTop: "30px", marginBottom: "50px" }}
                    id="transferir"
                    className="table table-hover table-bordered text-center"
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Nome</th>
                        <th>Endereço</th>
                        <th>Bairro</th>
                        <th>Cidade</th>
                        <th>Contato</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // map eh equivalente a um for ou foreach em react
                        clientes.map((cliente) => (
                          <tr key={cliente.cli_codigo}>
                            <td>{cliente.cli_nome}</td>
                            <td>{cliente.cli_endereco}</td>
                            <td>{cliente.cli_bairro}</td>
                            <td>{cliente.municipio}</td>
                            <td>{cliente.cli_fone}</td>
                            <td>
                              {status_array.indexOf(cliente.cli_codigo) !==
                              -1 ? (
                                <button
                                  type="submit"
                                  title="Editar"
                                  className="btn btn-danger"
                                  style={{ fontSize: "12px" }}
                                  onClick={(e) =>
                                    Desfazer(cliente.cli_codigo, e)
                                  }
                                >
                                  {" "}
                                  Desfazer&nbsp;
                                  <i className="fas fa-exchange-alt"></i>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  title="Editar"
                                  className="btn btn-primary"
                                  style={{ fontSize: "12px" }}
                                  onClick={(e) =>
                                    Transferir(cliente.cli_codigo, e)
                                  }
                                >
                                  {" "}
                                  Transferir&nbsp;
                                  <i className="fas fa-exchange-alt"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

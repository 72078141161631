import React, { Component } from "react";
import { api, handleWarn } from "../Plugins/Config/Config";
import "../styles/Visita.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mask_data } from "../Plugins/Mascara/Mascara";

class Visita extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientes: [],
    };
  }

  componentDidMount() {
    const { cli_codigo } = this.props.match.params;

    api
      .get("sicoob/cv/" + cli_codigo, {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((res) => {
        if (res.data !== "") {
          this.setState({ clientes: res.data });
          //  console.log("res do axios "+JSON.stringify(res.data[0].prod_preco));
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          handleWarn(error.response.data.message);
        }
      });
  }
  render() {
    const { clientes } = this.state;

    return (
      <>
        <div
          className="container"
          style={{ marginTop: "70px", marginBottom: "50px" }}
        >
          <div class="shadow-lg  mb-5 bg-white rounded card">
            <div class="card-header">
              <h3 className="font-weight-bold text-center">
                Relatório de Visita
              </h3>
            </div>
            <div class="card-body">
              {clientes.length > 0 ? (
                <div id="customers" className="table-responsive">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success"
                    table="table-to-xls"
                    filename="Relatorio de Visitas"
                    sheet="tablexls"
                    buttonText="Export em XLS"
                  />
                  <table
                    id="table-to-xls"
                    style={{ marginTop: "30px", marginBottom: "50px" }}
                    className="table table-hover table-bordered text-center"
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>Nome</th>
                        <th>Gerente</th>
                        <th>Horário</th>
                        <th>Data</th>
                        <th>Objetivo</th>
                        <th>Resultado</th>
                        <th>Descrição</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // map eh equivalente a um for ou foreach em react
                        clientes.map((cliente) => (
                          <tr key={cliente.cv_id}>
                            <td>{cliente.cli_nome}</td>
                            <td>{cliente.nome}</td>
                            <td>
                              {cliente.cv_horainicio +
                                " - " +
                                cliente.cv_horafim}
                            </td>
                            <td>{mask_data(cliente.cv_data)}</td>
                            <td>{cliente.descricao_objetivo}</td>
                            <td>{cliente.descricao_resultado}</td>
                            <td>{cliente.cv_resultadohistorico}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  style={{ marginTop: "80px" }}
                  className="shadow-lg p-3 mb-5 bg-white rounded"
                >
                  Nenhuma visita foi cadastrada para este cliente até este
                  momento.
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Visita;

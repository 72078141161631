import React, {Component} from 'react';

class Error404 extends Component {
    render() {
        return (
            <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">404</span>
                        <div className="mb-4 lead">A página que você está procurando não foi encontrada..</div>
                        </div>
                </div>
            </div>
        </div>
        
        );
    }
}
export default Error404;